import { Component, Input, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { SelectedService, PriceSetPrice } from '../../../../generated/models';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from "moment";

@Component({
  selector: 'mp-selected-services-list',
  templateUrl: './selected-services-list.component.html',
  styleUrls: ['./selected-services-list.component.scss']
})
export class SelectedServicesListComponent implements OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  markedServices: SelectedService[] = [];
  private _prices: { [serviceId: number]: number } = {};

  @Input("canRemoveService") canDeleteService: boolean;
  @Input("canRefundService") canRefundService: boolean;

  @Input() date: moment.Moment;
  @Input() age: number;
  @Input() discount: number;
  @Input() services: SelectedService[] = [];
  @Input() prices: PriceSetPrice[] = [];

  @Output("requestRemove")
  onRemove = new EventEmitter<number[]>();

  @Output("requestRefund")
  onRefund = new EventEmitter<number[]>();

  @Output("requestQuantityChange")
  onQuantityChange: EventEmitter<{ service: SelectedService, quantity: number }>
    = new EventEmitter<{ service: SelectedService, quantity: number }>();

  @Output("requestPerformer")
  requestPerformer = new EventEmitter<SelectedService>();

  @Output("requestContainer")
  requestContainer = new EventEmitter<SelectedService>();

  get hasDiscount(): boolean { return this.discount > 0 && this.discount <= 100; }
  get hasServices(): boolean { return this.services.length > 0; }
  get hasMarkedServices(): boolean { return this.markedServices.length > 0; }

  get deleteServiceDisabled(): boolean { return !this.canDeleteService || this.markedServices.length === 0; }
  get refundServiceDisabled(): boolean {
    if (!this.canRefundService) return true;

    return !this.markedServices.some(x => x.id > 0 && (x.receiptId > 0 && x.receiptStatus === 1) && (!x.refundId || x.refundStatus !== 1));
  }

  get showRefundBtn(): boolean { return this.services.some(x => x.receiptId > 0 && x.receiptStatus === 1); }

  get total(): number { return this.services.map(x => x.quantity).reduce((x, y) => x + y, 0); }

  checkbox = new FormControl(false, []);

  constructor() {
    this.checkbox.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => this.markedServices = value ? [...this.services] : []);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['prices']) {
      const value: PriceSetPrice[] = changes['prices'].currentValue;
      this._prices = {};

      if (value) {
        value.forEach(item => this._prices[item.serviceId] = item.amount);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  marked = (service: SelectedService) => this.markedServices.some((x: SelectedService): boolean => x.id === service.id);

  toggle(service: SelectedService, value: boolean): void {
    const index = this.markedServices.findIndex(x => x.id === service.id);

    if (value && index === -1) this.markedServices.push(service);
    if (!value && index !== -1) this.markedServices.splice(index, 1);
  }

  priceExistsFor = (id: number) => !!this._prices[id];
  grossPriceFor = (id: number) => this._prices[id] || 0;
  priceFor = (id: number) => Math.round(this.grossPriceFor(id) * (100 - (this.discount || 0)) / 100);

  onRequestPerformer = (service: SelectedService) => this.requestPerformer.emit(service);
  onRequestContainer = (service: SelectedService) => this.requestContainer.emit(service);

  remove() {
    if (this.deleteServiceDisabled) return;

    this.onRemove.emit(this.markedServices.map(x => x.id));
    this.checkbox.setValue(false);
  }

  refund() {
    if (this.refundServiceDisabled) return;

    const toRefund = this.markedServices.filter(x => x.id > 0 && (x.receiptId > 0 && x.receiptStatus === 1) && (!x.refundId || x.refundStatus !== 1));

    if (toRefund.length === 0) return;

    this.onRefund.emit(toRefund.map(x => x.id));

    this.checkbox.setValue(false);
  }

  public changeQuantity(service: SelectedService, quantity: number) {
    this.onQuantityChange.emit({ service: service, quantity: quantity });
  }
}
