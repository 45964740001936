import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  SelectedService,
  Document,
  PriceSet,
  CustomerChannel,
  ColorMark,
  PriceSetPricesResponse,
  PriceSetPrice,
  ServiceCategory,
  Service,
  Patient,
  Registrator,
  Partner,
  PartnersCompany,
  LocusesSearchResponse,
  SelectedLocusContainer,
  Performer,
  PerformersResponse,
  AddService,
  AddLocusContainer,
  Company,
  VisitResult,
  EditSelectedServiceModel,
  AvailableDiscount,
  DuplicateSearchResponse,
  PatientSearchResult,
  ServiceOptionsResponse,
  AddVisitOption,
  EditOption,
  AddVisitModel,
  EditVisitModel,
  RefundReason,
  PersonalBalance,
  PersonDocument,
  SocialStatusModel,
  PatientPrescriptedManipulation,
  PersonMark,
  ServiceOption
} from 'projects/Clinic/src/app/generated/models';
import {
  VisitsService,
  PatientsService,
  PriceSetsService,
  PartnersService,
  LocusesService,
  CashboxService,
  DocumentsService,
  ServicesService,
  DiscountsService,
  CompaniesService,
  SocialStatusesService,
  MyService,
  PeopleService,
  VisitReportsService,
  SuggestionsService,
  RefundReasonsService,
  PersonalBalanceService,
  PaymentsService,
  RefundsService,
  PersonDocumentsService,
  PatientPrescriptionsService,
  PersonMarksService
} from 'projects/Clinic/src/app/generated/services';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { VisitsContainerSelectModalComponent } from '../visits-container-select-modal/visits-container-select-modal.component';
import { VisitsPerformerSelectModalComponent } from '../visits-performer-select-modal/visits-performer-select-modal.component';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import * as moment from 'moment';
import { SelectClientModalComponent } from '../select-client-modal/select-client-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { VisitPayload } from '../../resolvers/visit-resolver';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, of, BehaviorSubject, from, combineLatest } from 'rxjs';
import { takeUntil, tap, switchMap, map, debounceTime, filter, share } from 'rxjs/operators';
import { UserStorage } from '../../../../services/user-storage';
import { SettingsStorage } from '../../../../services/settings-storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PermissionNames } from '../../../../models/permission-names';
import { EditLocusContainer } from '../../../../generated/models/edit-locus-container';
import { DiscountValue } from '../discount-field/discount-field.component';
import { OmsData } from '../oms/oms.component';
import { VisitDuplicateModalComponent } from '../visit-duplicate-modal/visit-duplicate-modal.component';
import { OptionChange } from '../options/options.component';
import { SelectedOption } from '../../../../generated/models/selected-option';
import { DmsInfo } from '../dms-info/dms-info.component';
import { RefundModalComponent } from '../refund-modal/refund-modal.component';
import { EventNames, MessageReceiverService, ReceiptCreatedPayload, ReceiptUpdatedPayload, RefundCreatedPayload } from '../../../../services/message.receiver.service';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';
import { DocumentPreviewService } from '../../../shared/services/document-preview-service';
import { PersonMarkModalService } from '../../../person-marks/services/person-mark-modal.service';
import { PersonMarkModalComponent, PersonMarkModalPayload } from '../../../person-marks/components/person-mark-modal/person-mark-modal.component';
import { SelectedServiceDetails, ServiceDetailsModalComponent } from '../service-details-modal/service-details-modal.component';

@Component({
  selector: 'mp-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.scss'],
  host: { class: "page" }
})
export class VisitComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  private _printSource$: Subject<string> = new Subject<string>();
  private _permissionsCheck$: Subject<void> = new Subject<void>();
  private _serviceValidityCheck$: Subject<void> = new Subject<void>();
  private _patientSubject$: Subject<Patient> = new Subject<Patient>();
  private _representativeSubject$: Subject<Patient> = new Subject<Patient>();
  private _contactsSubject$: Subject<Contacts> = new Subject<Contacts>();
  private _servicesSelected$ = new Subject<SelectedService[]>();
  private _pricesSource$: BehaviorSubject<PriceSetPrice[]> = new BehaviorSubject<PriceSetPrice[]>([]);
  private _priceSetsSource$: BehaviorSubject<PriceSet[]> = new BehaviorSubject<PriceSet[]>([]);
  private _prescriptionsSource$: BehaviorSubject<PatientPrescriptedManipulation[]> = new BehaviorSubject<PatientPrescriptedManipulation[]>([]);
  private _insuranceCompanies$: Observable<Company[]>;
  private _results$: Observable<VisitResult[]>;
  private _socialStatuses$: Observable<SocialStatusModel[]>;
  private _discounts$: Observable<AvailableDiscount[]>;
  private _prices$: Observable<PriceSetPrice[]>;

  private _prices: { [serviceId: number]: number } = {};

  private _transient: boolean;
  private _created: moment.Moment;

  options: SelectedOption[] = [];
  services: Service[] = [];
  categories: ServiceCategory[] = [];
  customerChannels: CustomerChannel[] = [];
  colorMarks: ColorMark[] = [];
  registrators: Registrator[] = [];
  partners: Partner[];
  partnerCompanies: PartnersCompany[] = [];
  omsInsuranceCompanies: Company[] = [];
  dmsInsuranceCompanies: Company[] = [];
  customerCompanies: Company[] = [];
  documents: PersonDocument[] = [];
  marks: PersonMark[] = [];
  selectedServices: SelectedService[] = [];
  priceSets: PriceSet[] = [];
  genders = [{ text: "Муж.", value: 2 }, { text: "Жен.", value: 1 }];

  id: number;
  personId: number;
  patientId: number;
  representativeId: number;

  companyId: number;
  companyName: string;

  payed: number;
  total = 0;
  paymentType: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  payment: number;

  primary: boolean;
  presetPatient: boolean;
  age: number;
  discountReasonRequired: boolean;
  totalSpendings: number;
  noPhone: boolean;
  submittedOnce: boolean;
  receiptPrinted: boolean;
  invalidServices: boolean;

  processing: boolean;
  loadingServices: boolean;
  loadingUserData: boolean;
  loadingDocuments: boolean;

  omsData: OmsData;
  discountValue: DiscountValue;
  dmsInfo: DmsInfo = { certificate: '', insuranceCompanyId: undefined, guaranteeNumber: '', guaranteeDate: '' };

  date = '';

  canAddMark = false;
  canManageMarks = false;

  canAddService: boolean;
  canRemoveService: boolean;
  canPrintReceipt: boolean;
  canRefundService: boolean;
  canPrintInvoice: boolean;
  canPrintContract: boolean;
  canPrintAgreement: boolean;
  canPrintConsent: boolean;
  canPrintPreliminaryInvoice = false;
  canChangePayment: boolean;
  canChangePriceSet: boolean;
  canChangeDate: boolean;
  canChangeRegistrator: boolean;
  canChangePersonalNumber: boolean;
  canSearchPatient: boolean;
  canSearchRepresentative: boolean;
  canChangeForm: boolean;
  canSwitchRepresentative: boolean;
  canChangePaymentSource: boolean;
  canSave: boolean;

  omsEnabled = false;
  dmsEnabled = false;
  contractEnabled = false;
  budgetEnabled = false;
  balanceEnabled = false;

  showDocumentsTab: boolean;
  showPartnerField: boolean;
  showReferralCompanyField: boolean;
  showDiscountFields: boolean;
  showOmsFields: boolean;
  showDmsFields: boolean;
  showContractFields: boolean;

  dmsFieldsDisabled = false;

  allowAnonymous = false;
  isAnonymous = false;

  balance = 0;

  patientDataForm: FormGroup;
  priceSetControl = new FormControl(0, [Validators.required]);
  dateControl = new FormControl(moment(), [Validators.required]);
  timeControl = new FormControl({ hour: 0, minute: 0 }, [Validators.required]);
  registratorControl = new FormControl(this.userStorage.profile.id, [Validators.required]);
  personalNumberControl = new FormControl(undefined, [Validators.pattern("[0-9]{0,}")]);
  ignorePhoneControl = new FormControl(false);
  ignoreHeadedBy = new FormControl(false);
  isAnonymousControl = new FormControl(false);
  hasRepresentativeControl = new FormControl(false);
  representativeFormGroup: FormGroup;
  contactsFormGroup: FormGroup;
  addressFormGroup = new FormGroup({ common: new FormControl("", []) });

  referralRequired = false;

  get visitDate(): moment.Moment { return this.dateControl.value; }

  get insuranceCompanies$(): Observable<Company[]> { return this._insuranceCompanies$; }
  get results$(): Observable<VisitResult[]> { return this._results$; }
  get socialStatuses$(): Observable<SocialStatusModel[]> { return this._socialStatuses$; }
  get discounts$(): Observable<AvailableDiscount[]> { return this._discounts$; }
  get prices$(): Observable<PriceSetPrice[]> { return this._prices$; }
  get pricesSource(): Observable<PriceSetPrice[]> { return this._pricesSource$; }
  get prescriptionsSource(): Observable<PatientPrescriptedManipulation[]> { return this._prescriptionsSource$; }
  get priceSets$(): Observable<PriceSet[]> { return this._priceSetsSource$; }

  get discount(): number { return this.discountValue ? this.discountValue.amount : undefined; }

  //Preferences
  get showAdditionalPatientsFields(): boolean { return this.settingsStorage.global.visitAdditionalPatientsFields; }

  //Permissions

  get canClearPatient(): boolean { return this.patientId > 0 || this.personId > 0; }
  get canClearRepresentative(): boolean { return this.representativeId > 0; }

  get hasCashbox(): boolean { return !!this.userStorage.profile.cashboxId; }
  get hasDocumentsPrinter(): boolean { return !!this.userStorage.profile.documentPrinterName; }

  //Validation

  get genderInvalid(): boolean {
    const control: AbstractControl = this.patientDataForm.get("gender");
    return !!control && control.touched && control.invalid;
  }

  get printTitle(): string {
    return this.hasDocumentsPrinter ? `Печать на устройстве ${this.userStorage.profile.documentPrinterName}` : "Принтер не установлен";
  }

  get cashboxTitle(): string {
    return this.hasCashbox ? `Печать на устройстве ${this.userStorage.profile.cashboxName}` : "Принтер чеков не установлен";
  }

  get invalidPatient(): boolean {
    if (this.patientDataForm.invalid) return true;

    if (this.paymentType === 4 && (!this.dmsInfo.insuranceCompanyId || !this.dmsInfo.certificate)) return true;

    if (this.discountValue.id > 0 && (this.paymentType === 1 || this.paymentType === 2)) {
      if (!this.discountValue.amount || this.discountValue.amount < 1 || this.discountValue.amount > 100) {
        return true;
      }

      if (this.discountReasonRequired && !this.discountValue.reason) return true;
    }

    if (this.omsData && this.omsData.snils && this.omsData.snils.length !== 11) return true;

    return false;
  }

  get exists(): boolean { return this.id > 0; }

  get patientName(): string {
    return [
      this.patientDataForm.get('lastName').value,
      this.patientDataForm.get('firstName').value,
      this.patientDataForm.get('middleName').value
    ].filter(x => x).join(' ').trim();
  }

  get patientCardNo(): string { return this.personalNumberControl.value; }

  get showRepresentativeForm(): boolean { return this.hasRepresentativeControl.value; }

  get commercial(): boolean { return [1, 2, 7].includes(this.paymentType); }

  get showDocumentDateError(): boolean {
    return this.patientDataForm.get('documentDate').touched && this.patientDataForm.get('documentDate').invalid
  }

  constructor(
    private visitsService: VisitsService,
    private modal: NgbModal,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private patientsService: PatientsService,
    private priceSetsService: PriceSetsService,
    private partnersService: PartnersService,
    private locusesService: LocusesService,
    private toastrService: ToastrService,
    private cashboxService: CashboxService,
    private documentsService: DocumentsService,
    private servicesService: ServicesService,
    private userStorage: UserStorage,
    private settingsStorage: SettingsStorage,
    private discountsService: DiscountsService,
    private companiesService: CompaniesService,
    private socialStatusesService: SocialStatusesService,
    private myService: MyService,
    private peopleService: PeopleService,
    private visitReportsService: VisitReportsService,
    private suggestionsService: SuggestionsService,
    private refundReasonsService: RefundReasonsService,
    private personalBalanceService: PersonalBalanceService,
    private paymentsService: PaymentsService,
    private refundsService: RefundsService,
    messageReceiverService: MessageReceiverService,
    private personDocumentsService: PersonDocumentsService,
    private confirmationService: ConfirmationModalService,
    private previewService: DocumentPreviewService,
    private patientPrescriptionsService: PatientPrescriptionsService,
    private personMarksService: PersonMarksService,
    private personMarkModalServuce: PersonMarkModalService
  ) {

    this._created = moment();

    this.canAddMark = this.userStorage.hasPermission(PermissionNames.AddPersonMarks);
    this.canManageMarks = this.userStorage.hasPermission(PermissionNames.ManagePersonMarks);

    this.showDocumentsTab = userStorage.profile.showDocumentsTab;
    this.omsEnabled = userStorage.profile.omsEnabled;
    this.dmsEnabled = userStorage.profile.dmsEnabled;
    this.contractEnabled = userStorage.profile.contractEnabled;
    this.budgetEnabled = userStorage.profile.budgetEnabled;
    this.balanceEnabled = userStorage.profile.enableBalanceOperations;

    this.partners = [{ id: undefined, name: " " }];

    this.discountValue = { id: 0, amount: undefined, reason: "" };

    this.omsData = { citizenship: 0, disability: 0, result: 0, socialStatus: 0 };

    messageReceiverService.forEvent(EventNames.ReceiptCreated)
      .pipe(filter(x => x.visitId == this.id))
      .subscribe((event: ReceiptCreatedPayload) => {
        for (let service of this.selectedServices) {
          if (event.contractItems.includes(service.id)) {
            service.receiptId = event.receiptTaskId;
            service.receiptStatus = 0;
          }
        }
      });

    messageReceiverService.forEvent(EventNames.RefundCreated)
      .pipe(filter(x => x.visitId == this.id))
      .subscribe((event: RefundCreatedPayload) => {
        for (let service of this.selectedServices) {
          if (event.contractItems.includes(service.id)) {
            service.refundId = event.receiptTaskId;
            service.refundStatus = 0;
          }
        }
      });

    messageReceiverService.forEvent(EventNames.ReceiptUpdated)
      .subscribe((event: ReceiptUpdatedPayload) => {

        this.selectedServices.filter(x => x.receiptId === event.receiptTaskId).forEach(x => x.receiptStatus = event.status);
        this.selectedServices.filter(x => x.refundId === event.receiptTaskId).forEach(x => x.refundStatus = event.status);

        if (this.selectedServices.some(x => x.receiptId === event.receiptTaskId)) {
          switch (event.status) {
            case 1: this.toastrService.success('Печать чека завершена', 'Успешно'); break;
            case 2: this.toastrService.warning('Печать чека отменена', 'Отмена'); break;
            case 3: this.toastrService.error('Не удалось напечатать чек', 'Ошибка'); break;
            case 4: console.log('Чек отправлен на печать'); break;
            case 5: console.log('Чек в обработке'); break;
          }
        }

        if (this.selectedServices.some(x => x.refundId === event.receiptTaskId)) {
          switch (event.status) {
            case 1: this.toastrService.success('Печать чека завершена', 'Успешно'); break;
            case 2: this.toastrService.warning('Печать чека отменена', 'Отмена'); break;
            case 3: this.toastrService.error('Не удалось напечатать чек', 'Ошибка'); break;
            case 4: console.log('Чек отправлен на печать'); break;
            case 5: console.log('Чек в обработке'); break;
          }
        }
      });

    this._insuranceCompanies$ = this.companiesService.Companies({ Type: 3 });
    this._results$ = this.visitsService.AvailableResults();
    this._socialStatuses$ = this.socialStatusesService.Statuses();

    this.representativeFormGroup = new FormGroup({
      firstname: new FormControl("", [Validators.required]),
      middlename: new FormControl("", []),
      lastname: new FormControl("", [Validators.required]),

      payer: new FormControl(false),

      passportNumber: new FormControl(""),
      issuer: new FormControl(""),
      issued: new FormControl(""),
      issuerCode: new FormControl("")
    });

    this.contactsFormGroup = new FormGroup({
      phone: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.email])
    });

    const dobControl: FormControl = new FormControl(undefined, [Validators.required, minDob(moment('1900-01-01', 'YYYY-MM-DD')), maxDob(moment().add(1, 'day'))]);
    const referralDoctor: FormControl = new FormControl(undefined, [Validators.required]);
    const referralCompany: FormControl = new FormControl(undefined, [Validators.required]);

    const issuedControl = new FormControl(undefined, [isAfter(dobControl)]);

    this.patientDataForm = new FormGroup({
      cardNo: this.personalNumberControl,
      cardDate: this.dateControl,
      cardTime: this.timeControl,
      registeredBy: this.registratorControl,
      headedByOrg: referralCompany,
      headedByDoctor: referralDoctor,
      moneySavings: new FormControl(''),
      gotInfoFrom: new FormControl('', [Validators.required]),
      //личные данные человека
      lastName: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      middleName: new FormControl(''),
      dob: dobControl,
      gender: new FormControl('', [Validators.required, Validators.min(1)]),
      label: new FormControl(''),
      documentNo: new FormControl(''),
      documentDate: issuedControl,
      documentIssuedBy: new FormControl(''),
      issuerCode: new FormControl(""),
      //адрес регистрации
      settlement: new FormControl(''),
      street: new FormControl(''),
      build: new FormControl(''),
      corp: new FormControl(''),
      flat: new FormControl(''),

      contacts: this.contactsFormGroup,
      inn: new FormControl(''),

      hasRepresentative: this.hasRepresentativeControl,
      representative: this.representativeFormGroup,

      customerCompanyId: new FormControl(undefined, [Validators.required])
    });

    this.hasRepresentativeControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        if (value) {
          this.representativeFormGroup.enable();
          if (!this.isAnonymous) {
            this.removePatientDocumentValidators();
          }
        } else {
          this.representativeFormGroup.disable();
          if (!this.isAnonymous) {
            this.setPatientDocumentValidators()
          }
        }
      });

    this.priceSetControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value: number): void => {
          this._prices$ = this.priceSetsService.Prices({ id: value, PatientId: this.patientId })
            .pipe(
              map((response: PriceSetPricesResponse): PriceSetPrice[] => response.items),
              tap((prices: PriceSetPrice[]): void => {
                prices.forEach(x => this._prices[x.serviceId] = x.amount);

                this._pricesSource$.next(prices);
                this._serviceValidityCheck$.next();
                this.updateFinances();
              }),
              share()
            );
        }
      );

    referralDoctor.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        switchMap((value: number): Observable<PartnersCompany[]> => {
          if (!value) {
            return from([[{ companyId: this.companyId, companyName: this.companyName }]]);
          }

          return this.partnersService.PartnersCompanies(value);
        })
      )
      .subscribe(
        (response: PartnersCompany[]): void => {
          this.partnerCompanies = response;

          const primary: PartnersCompany = this.partnerCompanies.find(x => x.primary);
          const current: PartnersCompany = this.partnerCompanies.find(x => x.companyId === this.companyId);

          if (this.partnerCompanies.length === 1) {
            referralCompany.setValue(this.partnerCompanies[0].companyId);
          }
          else if (primary) {
            referralCompany.setValue(primary.companyId);
          } else if (current) {
            referralCompany.setValue(current.companyId);
          }
          else {
            referralCompany.setValue(undefined);
          }
        });

    this.ignorePhoneControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean): void => {
        const phoneControl: AbstractControl = this.contactsFormGroup.get("phone");

        if (value || !this.canChangeForm) {
          phoneControl.enabled ? phoneControl.disable() : undefined;
        }
        else {
          phoneControl.disabled ? phoneControl.enable() : undefined;
        }
      });


    this.ignoreHeadedBy.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        const control: AbstractControl = this.patientDataForm.get("headedByDoctor");

        if (value || !this.canChangeForm) {
          control.enabled ? control.disable() : undefined;
          control.setValue(null);
        }
        else {
          control.disabled ? control.enable() : undefined;
        }
      });

    this.isAnonymousControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean): void => {
        this.isAnonymous = value;
        if (this.isAnonymous) {
          this.patientDataForm.controls["documentNo"].setValue("");
          this.patientDataForm.controls["documentDate"].setValue(undefined);
          this.patientDataForm.controls["documentIssuedBy"].setValue("");
          this.removePatientDocumentValidators();
          this.hasRepresentativeControl.setValue(false);
        } else if (!this.hasRepresentativeControl.value) {
          this.setPatientDocumentValidators();
        }
      });

    dobControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value: moment.Moment): void => {
          issuedControl.updateValueAndValidity();

          if (value && value.isValid()) {
            this.age = moment().diff(value, "years");
          } else {
            this.age = undefined;
          }

          this._serviceValidityCheck$.next();
        }
      );

    combineLatest([this.dateControl.valueChanges, this.timeControl.valueChanges])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value: [moment.Moment, { hour: number, minute: number }]): void => {
          if (value[0] && value[0].isValid()) {
            const newDate = value[0].clone().hour(value[1].hour).minute(value[1].minute);

            this.date = newDate.format('DD.MM.YYYY HH:mm');
          }
        });

    this._patientSubject$
      .pipe(takeUntil(this.destroy$))
      .subscribe((patient: Patient): void => {
        this.documents = [];
        this._prescriptionsSource$.next([]);

        if (patient && patient.id) {
          this.patientId = patient.id;
          this.personId = patient.personId;

          this.loadMarks();

          const patch: any = {
            gotInfoFrom: patient.customerChannelId,
            cardNo: this.id > 0 || !this.primary ? patient.personalNumber : undefined,

            lastName: patient.lastname,
            firstName: patient.firstname,
            middleName: patient.middlename,

            gender: patient.gender,
            documentNo: patient.passportNumber,

            documentIssuedBy: patient.issuer,
            phone: patient.phone,
            mail: patient.email,
            address: patient.address,
            hasRepresentative: patient.hasRep,
            dob: undefined,
            documentDate: undefined,
            issuerCode: patient.issuerCode,

            customerCompanyId: patient.customerCompanyId
          };

          if (patient.dob) {
            patch.dob = moment(patient.dob, "DD.MM.YYYY");
          }

          if (patient.issued) {
            patch.documentDate = moment(patient.issued, "DD.MM.YYYY");
          }

          switch (patient.paymentSource) {
            case 1: { this.paymentType = 1; } break;
            case 2: { this.paymentType = 3; } break;
            case 3: { this.paymentType = 4; } break;
            case 4: { this.paymentType = 5; } break;
            case 5: { this.paymentType = 6; } break;
            default: break;
          }

          this.patientDataForm.patchValue(patch);

          this.loadDocuments();
          this.loadPrescriptions();

          let representative: Patient = null

          this.representativeId = undefined;

          this.omsData = {
            ...this.omsData,
            citizenship: patient.citizenship || 0,
            disability: patient.disability || 0,
            snils: patient.snils,
            socialStatus: patient.socialStatus || 0
          };

          if (patient.hasRep) {
            representative = {
              id: patient.repId,
              firstname: patient.repFirstname,
              middlename: patient.repMiddlename,
              lastname: patient.repLastneme,
              phone: patient.repPhone,
              address: patient.repAddress,
              email: patient.repEmail,
              passportNumber: patient.repPassportNumber,
              issued: patient.repIssued,
              issuer: patient.repIssuer,
              issuerCode: patient.representativeIssuerCode,
              inn: patient.representativeInn,
              payer: patient.payer
            };
          } else {
            this._contactsSubject$.next({ phone: patient.phone, email: patient.email });
            this.addressFormGroup.patchValue({ common: patient.address });
            this.patientDataForm.get('inn').setValue(patient.inn);
          }

          if (!representative) {
            this.loadBalance(patient.personId);
          }

          this._representativeSubject$.next(representative);

          this._permissionsCheck$.next();
          return;
        }

        if (patient && patient.personId) {
          this.personId = patient.personId;

          this.loadMarks();

          this.clearPatientForm();
          this._representativeSubject$.next(null);
          this.totalSpendings = undefined;

          this.patientDataForm.patchValue({
            lastName: patient.lastname,
            firstName: patient.firstname,
            middleName: patient.middlename,
            inn: patient.inn,

            gender: patient.gender,
            dob: patient.dob ? moment(patient.dob, "DD.MM.YYYY") : undefined
          });

          this._contactsSubject$.next({ phone: undefined, email: undefined });
          this.addressFormGroup.patchValue({ common: undefined });

          this.omsData = {
            ...this.omsData,
            citizenship: 0,
            disability: 0,
            snils: patient.snils,
            socialStatus: 0
          };

          this.loadBalance(patient.personId);
          this._permissionsCheck$.next();
          return;
        }

        this.patientId = undefined;
        this.personId = undefined;

        this.loadMarks();

        this.clearPatientForm();
        this._representativeSubject$.next(null);
        this.totalSpendings = undefined;

        this.omsData = {
          ...this.omsData,
          citizenship: 0,
          disability: 0,
          snils: "",
          socialStatus: 0
        };

        this.loadBalance(null);

        this._permissionsCheck$.next();
      });

    this._representativeSubject$
      .pipe(takeUntil(this.destroy$))
      .subscribe((representative: Patient): void => {
        if (!representative) {
          this.representativeId = undefined;
          this.representativeFormGroup.reset();
          return;
        }

        this.representativeId = representative.id;

        this.representativeFormGroup.patchValue({
          ...representative,
          issued: representative.issued ? moment(representative.issued, "DD.MM.YYYY") : undefined,
        });

        this._contactsSubject$.next({ phone: representative.phone, email: representative.email });
        this.addressFormGroup.patchValue({ common: representative.address });
        this.patientDataForm.get('inn').setValue(representative.inn);

        this.loadBalance(representative.id);
      });

    this._servicesSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (services: SelectedService[]) => {

        if (services.every(service => !service.specialityId && (!service.kdlCode || service.isExpress))) {
          this.confirmServicesSelection(services);
          return;
        }

        const date = this.dateControl.value.format('DD.MM.YYYY');

        const details = await this.servicesService.DetailsAsync({
          CompanyId: this.companyId,
          Date: date,
          ServiceIds: services.map(x => x.serviceId)
        }).toPromise();

        for (const service of services) {
          const detail = details.find(x => x.serviceId === service.serviceId);

          if (!detail) continue;

          if (service.specialityId > 0 && detail.performers && detail.performers.length === 1) {
            service.performer = { ...detail.performers[0] };
          }

          if (service.kdlCode && !service.isExpress) {
            for (const locus of detail.locuses) {
              for (const container of locus.containers) {
                if (locus.required && container.required) {

                  service.containers.push({
                    locusId: locus.id,
                    locusName: locus.name,
                    containerId: container.id,
                    containerName: container.name,
                    selectedServiceId: service.id
                  });
                }
              }
            }

          }
        }

        if (services.every(service => (!service.specialityId || !!service.performer)
          && (!service.kdlCode || service.isExpress))) {
          this.confirmServicesSelection(services);
          return;
        }

        const modalRef = this.modal.open(ServiceDetailsModalComponent, {
          size: 'lg',
          backdrop: 'static',
          centered: true
        });

        const componentRef: ServiceDetailsModalComponent = modalRef.componentInstance;
        componentRef.services = services;
        componentRef.details = details;

        componentRef.onCancel.subscribe(() => {
          modalRef.close();
        });

        componentRef.onConfirm.subscribe((items: SelectedServiceDetails[]) => {
          for (const service of services) {
            const item = items.find(x => x.id === service.id);
            if (!item) continue;

            if (item.performerId > 0) {
              service.performer = item.performers.find(x => x.id === item.performerId);
            }

            if (item.selectedLocusContainers && item.selectedLocusContainers.length > 0) {
              service.containers = item.selectedLocusContainers;
            }
          }

          this.confirmServicesSelection(services);
          modalRef.close();
        });

        return;
      });

    this._contactsSubject$.pipe(takeUntil(this.destroy$)).subscribe((value: { phone: string; email: string }) => this.contactsFormGroup.patchValue(value));

    this._permissionsCheck$.pipe(takeUntil(this.destroy$), debounceTime(500)).subscribe(() => this.onPermissionsCheck());

    this._serviceValidityCheck$.pipe(takeUntil(this.destroy$)).subscribe(() => this.onServiceValidityCheck());
    this._printSource$.pipe(takeUntil(this.destroy$)).subscribe(this.onPrintEvent);
  }

  confirmServicesSelection(services: SelectedService[]) {
    this.selectedServices = [...this.selectedServices, ...services];

    if (services.some(x => !!x.kdlCode && !x.isExpress)) {

      this.servicesService.OptionsAsync({
        ServiceIds: services.map(x => x.serviceId),
        CompanyId: this.companyId,
        Date: this.dateControl.value.format("DD.MM.YYYY"),
      }).subscribe((response: ServiceOption[]): void => {

        for (const item of response) {
          const index = this.options.findIndex(x => x.tag === item.tag && x.type === item.type);
          if (index !== -1) {
            this.options[index].services.push(item.serviceId);
          } else {
            this.options.push({
              name: item.name,
              tag: item.tag,
              type: item.type,
              value: "",
              services: [item.serviceId],
              items: item.dropdownItems
            });
          }
        }
      });
    }


    this.updateFinances();

    this._serviceValidityCheck$.next();
    this._permissionsCheck$.next();
  }

  onPrintEvent(token: string): void {
    const frame: HTMLIFrameElement = document.createElement("iframe");

    frame.style.display = "none";
    frame.src = `api/v1/Reports/Prepared?token=${token}&type=pdf`;

    document.body.append(frame);
    frame.contentWindow.print();
  }

  onServiceValidityCheck = () => this.invalidServices = this.selectedServices.some(x => !this.validateService(x));

  onPermissionsCheck(): void {
    const exists = this.id > 0;
    const canFix = moment().diff(this._created, "hours") < 24;
    const hasEditAccess = this.userStorage.hasPermission(PermissionNames.EditVisit);
    const hasPrinted = this.receiptPrinted || this.selectedServices.some(x => x.receiptId > 0 && x.receiptStatus === 1);
    const hasNotPrinted = this.selectedServices.some(x => !x.receiptId || x.receiptStatus !== 1);
    const hasDebt = this.total > this.payment;
    const hasNotRefunded = this.selectedServices.some(x => !x.refundId || x.refundStatus !== 1);
    const hasServicesWithPerformers = this.selectedServices.some(x => (!x.refundId || x.refundStatus !== 1) && !!x.performer);

    const hasFinancesAccess = this.userStorage.hasPermission(PermissionNames.EditVisitFinances);
    const canEditPersonData = this.userStorage.hasPermission(PermissionNames.EditPersonalData);

    const hasDocumentPrinter = true;//this.userStorage.profile.documentPrinterId > 0;
    const hasFiscalPrinter = this.userStorage.profile.cashboxId > 0;

    const hasPatient = this.patientId > 0;

    this.canChangePaymentSource = !this.presetPatient || this.primary;

    //Можно добавлять услуги, если
    //1) Пользователь может менять финансы
    //2) или Посещение ещё не создано
    //3) или Прошло менее 24 часов с момента создания и чек не печатался
    //        this._canAddService = hasFinancesAccess || !exists || (canFix && !hasPrinted);

    this.canAddService = hasFinancesAccess || !exists || (!hasPrinted && (hasEditAccess || canFix));

    //Можно удалять услуги, если
    //1) Пользователь может менять финансы
    //2) или Посещение ещё не создано
    //3) или Прошло менее 24 часов с момента создания и чек не печатался
    this.canRemoveService = hasFinancesAccess || !exists || (!hasPrinted && (hasEditAccess || canFix));

    //Печатать чек можно, если
    // 1) Есть, где печатать
    // 2) Нет долга
    // 3) Есть услуги, для которых чек не был распечатан - переносится на сервер
    // 4) Пользователь имеет расширенные полномочия или чек ещё не печатался - переносится на сервер
    // 5) Тип оплаты - не ОМС
    this.canPrintReceipt = hasFiscalPrinter && !hasDebt && hasNotPrinted && (hasFinancesAccess || !hasPrinted) && [1, 2, 7].includes(this.paymentType);
    //this._canPrintReceipt = (this._id > 0 || this._selectedServices.length > 0) && hasFiscalPrinter && !hasDebt && this._paymentType !== 3;

    //Делать возврат можно, если
    // 1) Посещение сохранено в БД
    // 2) У пользователя есть, где распечатать чек
    // 3) Тип оплаты - не ОМС
    this.canRefundService = exists && this.userStorage.profile.cashboxId > 0 && [1, 2, 7].includes(this.paymentType);

    // Печатать счет можно, если
    // 1) У пользователя есть принтер для документов
    // 2) В посещении есть услуги, для которых не был оформлен возврат
    this.canPrintInvoice = hasDocumentPrinter && hasNotRefunded;

    // Печатать договор можно, если
    // 1) У пользователя есть принтер для документов
    // 2) В посещении есть услуги, для которых не был оформлен возврат
    this.canPrintContract = hasDocumentPrinter && hasNotRefunded;

    //Печатать согласие на обработку ПД можно, если
    // 1) У пользователя есть принтер для документов
    this.canPrintAgreement = hasDocumentPrinter;

    //Печатать согласие на МВ можно, если
    // 1) У пользователя есть принтер для документов
    // 2) В посещении есть услуги, для которых определен исполнитель
    this.canPrintConsent = hasDocumentPrinter && hasServicesWithPerformers;

    //Изменение прайс-листа разрешено, если
    // 1) Посещение не сохранено в БД
    // 2) или Пользователь может енять финансы
    // 3) или Прошло менее 24 часов И чек не печатался
    this.canChangePriceSet = !exists || hasFinancesAccess || (!hasPrinted && (hasEditAccess || canFix));

    //Изменение внесенной суммы разрешено, если
    // 1) Пользователь может менять финансы
    // 2) или Чек не печатался
    this.canChangePayment = hasFinancesAccess || !hasPrinted;

    // Сохранять посещение можно, если
    // 1) Оно ещё не создано
    // 2) или Пользователь имеет расширенные полномочия
    // 3) или Прошло менее 24 часов 
    this.canSave = !this.exists || hasEditAccess || canFix || canEditPersonData;

    // Изменять дату посещения может пользователь с расширенными правами или в течение суток, если чек не печатался
    this.canChangeDate = hasFinancesAccess || (hasEditAccess && !hasPrinted) || (!hasPrinted && canFix);

    // Изменять номер карты пациента можно, если
    // 1) Пользователь имеет расширенные полномочия
    // 2) или Введен новый пациент
    this.canChangePersonalNumber = this.userStorage.profile.cardNumberChangeEnabled && (hasEditAccess || !hasPatient);

    // Искать (изменять, очищать) пациента можно, если
    // 1) Посещение ещё не создано
    // 2) Пациент для посещения не был выбран заранее
    this.canSearchPatient = !exists && !this.presetPatient;

    // Искать (изменять, очищать) представителя можно, если
    // 1) Посещение ещё не создано
    // 2) У пользователя есть расширенные полномочия
    // 3) Прошло меняя 24 часов с момента сохранения
    this.canSearchRepresentative = !exists || hasEditAccess || canFix || canEditPersonData;

    // Данные формы можно менять, если
    // 1) Посещение ещё не создано
    // 2) У пользователя есть расширенные полномочия
    // 3) Прошло менее 24 часов с моменты сохранения
    this.canChangeForm = !exists || hasEditAccess || canFix || canEditPersonData;

    // Включать/отключать представителя можно, если
    // 1) Посещение ещё не создано
    // 2) У пользователя есть расширенные полномочия
    // 3) Прошло меняя 24 часов с момента сохранения
    this.canSwitchRepresentative = !exists || hasEditAccess || canFix || canEditPersonData;

    // Изменять регистратора можно, если
    // 1) Чек не печатался и есть разрешение на смену анных пациента
    // или
    // 2) Чек напечатан и есть разрешение на смену финансовой информации
    this.canChangeRegistrator = (!hasPrinted && canEditPersonData) || (hasPrinted && hasFinancesAccess);

    this.canPrintPreliminaryInvoice = this.selectedServices.length > 0;

    if (this.canChangeForm) {
      this.patientDataForm.disabled ? this.patientDataForm.enable() : undefined;
    } else {
      this.patientDataForm.enabled ? this.patientDataForm.disable() : undefined;
    }

    if (this.canChangePriceSet) {
      this.priceSetControl.disabled ? this.priceSetControl.enable() : undefined;
    } else {
      this.priceSetControl.enabled ? this.priceSetControl.disable() : undefined;
    }

    if (this.canChangeDate) {
      this.dateControl.disabled ? this.dateControl.enable() : undefined;
      this.timeControl.disabled ? this.timeControl.enable() : undefined;
    } else {
      this.dateControl.enabled ? this.dateControl.disable() : undefined;
      this.timeControl.enabled ? this.timeControl.disable() : undefined;
    }

    if (this.canChangePersonalNumber) {
      this.personalNumberControl.disabled ? this.personalNumberControl.enable() : undefined;
    } else {
      this.personalNumberControl.enabled ? this.personalNumberControl.disable() : undefined;
    }

    this.canChangeRegistrator ? this.registratorControl.enable({ emitEvent: false }) : this.registratorControl.disable({ emitEvent: false });

    if (this.paymentType === 1 || this.paymentType === 2) {
      this.showDiscountFields = true;
      this.showPartnerField = true;
      this.showReferralCompanyField = true;

      this.showOmsFields = false;
      this.showDmsFields = false;
      this.showContractFields = false;
    } else {
      this.showDiscountFields = false;
      this.showPartnerField = false;
      this.showReferralCompanyField = false;

      this.showOmsFields = this.paymentType === 3;
      this.showDmsFields = this.paymentType === 4;
      this.showContractFields = this.paymentType === 5;
    }

    if (this.paymentType === 5 && this.primary && this.canChangeForm) {
      this.patientDataForm.get('customerCompanyId').enable({ emitEvent: false });

    } else {
      this.patientDataForm.get('customerCompanyId').disable({ emitEvent: true });
    }

    this.dmsFieldsDisabled = !this.showDmsFields || (!this.primary && this.presetPatient) || (!hasEditAccess && !canEditPersonData && !canFix);

    const headedByControl = this.patientDataForm.get("headedByDoctor");
    this.referralRequired = !this.selectedServices.some(x => x.prescribedById > 0);
    if (this.referralRequired) {
      headedByControl.setValidators([Validators.required]);
    } else {
      headedByControl.clearValidators();
    }

    headedByControl.updateValueAndValidity();
  }

  ngOnInit() {

    this.activatedRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: { payload: VisitPayload }): void => {
          this.id = data.payload.visit.id;
          this.primary = data.payload.primary;

          this._transient = !(this.id > 0);
          this.presetPatient = this.activatedRoute.snapshot.queryParamMap.has("patientId");

          if (this.id > 0) {
            this.companyId = data.payload.visit.companyId;
            this.companyName = data.payload.visit.companyName;
          } else {
            this.companyId = this.userStorage.profile.companyId;
            this.companyName = this.userStorage.profile.companyName;
          }

          if ((this.id > 0 || !this.primary) && data.payload.patient.id > 0) {
            if (data.payload.patient.paymentSource === 3) {
              this.dmsInfo = {
                insuranceCompanyId: data.payload.patient.insuranceCompanyId,
                certificate: data.payload.patient.policy,
                guaranteeNumber: data.payload.visit.guaranteeLetterNumber,
                guaranteeDate: data.payload.visit.guaranteeLetterDate
              }
            }
          }

          this.hasRepresentativeControl.setValue(data.payload.patient.repId > 0);
          this._patientSubject$.next(data.payload.patient);

          this._created = moment(data.payload.visit.created, "DD.MM.YYYY HH:mm");
          const date = moment(data.payload.visit.date, "DD.MM.YYYY HH:mm");

          if (this.id > 0) {
            this._discounts$ = this.visitsService.Discounts(this.id);
          } else if (data.payload.patient.id > 0) {
            date.format("DD.MM.YYYY")
            this._discounts$ = this.patientsService.Discounts({ id: data.payload.patient.id, Date: date.format("DD.MM.YYYY"), CompanyId: this.companyId });
          } else {
            this._discounts$ = this.discountsService.CommonDiscounts({ Date: date.format("DD.MM.YYYY"), CompanyId: this.companyId });
          }

          if (this.id > 0) {
            this.discountValue = {
              id: data.payload.visit.discountId || 0,
              reason: data.payload.visit.discountReason,
              amount: data.payload.visit.discountAmount,
            };
          } else if (data.payload.patient.id > 0) {
            this.discountValue = {
              id: data.payload.patient.defaultDiscountId || 0,
              reason: data.payload.patient.defaultDiscountReason,
              amount: data.payload.patient.defaultDiscountValue,
            };

            this.totalSpendings = data.payload.patient.spendings;
          }

          this.receiptPrinted = data.payload.visit.receiptPrinted;

          this.priceSets = data.payload.priceSets;

          if (!this.priceSets.some(x => x.id === data.payload.visit.priceSetId)) {
            this.priceSets.unshift({ id: data.payload.visit.priceSetId, name: data.payload.visit.priceSetName });
          }

          const relevantPriceSets = this.recalculateRelevantPriceSets(this.dmsInfo.insuranceCompanyId, !!this.dmsInfo.guaranteeNumber);

          this._priceSetsSource$.next(relevantPriceSets);

          if (this.id > 0) {
            this.priceSetControl.setValue(data.payload.visit.priceSetId);
          } else {
            const selected: PriceSet = this.selectMostSpecificPriceSet(relevantPriceSets, this.dmsInfo.insuranceCompanyId);
            this.priceSetControl.setValue(selected.id);
          }

          this.customerChannels = data.payload.channels;

          this.colorMarks.push(...data.payload.colorMarks);
          this.registrators = data.payload.registrators;

          this.services = data.payload.services;
          this.categories = data.payload.serviceCategories;

          this.omsInsuranceCompanies = data.payload.omsInsuranceCompanies;
          this.dmsInsuranceCompanies = data.payload.dmsInsuranceCompanies;

          if (!this.registrators.some((x: Registrator): boolean => x.id === data.payload.visit.registratorId)) {
            this.registrators.push({ id: data.payload.visit.registratorId, name: data.payload.visit.registratorName });
          }

          if (data.payload.patient.customerChannelId > 0) {
            if (!this.customerChannels.some((x: CustomerChannel): boolean => x.id === data.payload.patient.customerChannelId)) {
              this.customerChannels.push({ id: data.payload.patient.customerChannelId, name: data.payload.patient.customerChannelName });
            }
          }

          this.partnersService.Partners({ Date: date.format("DD.MM.YYYY"), CompanyId: this.companyId })
            .subscribe(
              (response: Partner[]): void => {
                this.partners.push(...response);

                if (data.payload.visit.partnerId > 0) {
                  if (!this.partners.some((x: Partner): boolean => x.id === data.payload.visit.partnerId)) {
                    this.partners.push({ id: data.payload.visit.partnerId, name: data.payload.visit.partnerName });
                  }

                  this.patientDataForm.get('headedByDoctor').setValue(data.payload.visit.partnerId, { emitEvent: false });
                } else if (data.payload.visit.id > 0 && !data.payload.visit.selectedServices.some(x => x.prescribedById > 0)) {
                  this.ignoreHeadedBy.setValue(true);
                }
              }
            );

          if (data.payload.visit.colormarkId > 0) {
            if (!this.colorMarks.some((x: ColorMark): boolean => x.id === data.payload.visit.colormarkId)) {
              this.colorMarks.push({ id: data.payload.visit.colormarkId, color: data.payload.visit.colormarkValue, name: data.payload.visit.colormarkName });
            }
          }

          const referralCompanyControl = this.patientDataForm.get('headedByOrg');

          if (data.payload.visit.partnerId > 0) {
            this.partnersService.PartnersCompanies(data.payload.visit.partnerId)
              .subscribe(
                (response: PartnersCompany[]): void => {
                  this.partnerCompanies = response;

                  if (data.payload.visit.referralCompanyId > 0) {
                    if (!this.partnerCompanies.some((x: PartnersCompany): boolean => x.companyId === data.payload.visit.referralCompanyId)) {
                      this.partnerCompanies.push({ companyId: data.payload.visit.referralCompanyId, companyName: data.payload.visit.referralCompanyName });
                    }

                    referralCompanyControl.setValue(data.payload.visit.referralCompanyId, { emitEvent: false });
                  } else {
                    const primary: PartnersCompany = this.partnerCompanies.find((x: PartnersCompany): boolean => x.primary);
                    const current: PartnersCompany = this.partnerCompanies.find((x: PartnersCompany): boolean => x.companyId === this.companyId);

                    if (this.partnerCompanies.length === 1) {
                      referralCompanyControl.setValue(this.partnerCompanies[0].companyId);
                    }
                    else if (primary) {
                      referralCompanyControl.setValue(primary.companyId);
                    } else if (current) {
                      referralCompanyControl.setValue(current.companyId);
                    }
                    else {
                      referralCompanyControl.setValue(undefined);
                    }
                  }
                }
              );
          } else {
            this.partnerCompanies = [{ companyId: this.companyId, companyName: this.companyName, }];
            referralCompanyControl.setValue(this.companyId);
          }

          this.selectedServices = data.payload.visit.selectedServices;
          this.options = [...data.payload.visit.selectedOptions];

          const patch: any = {
            registeredBy: data.payload.visit.registratorId,
            cardDate: date,
            cardTime: { hour: date.hour(), minute: date.minute() },
            label: data.payload.visit.colormarkId,
            result: data.payload.visit.visitsResult
          };

          this.omsData = {
            ...this.omsData,
            result: data.payload.visit.visitsResult,
            snils: data.payload.patient.snils,

            disability: data.payload.patient.disability || 0,
            socialStatus: data.payload.patient.socialStatus || 0,
            citizenship: data.payload.patient.citizenship || 0
          };

          this.patientDataForm.patchValue(patch);

          this.payed = data.payload.visit.payment;
          this.payment = this.payed;

          if (this._transient && !this.primary && data.payload.patient.id > 0) {

            switch (data.payload.patient.paymentSource) {
              case 2: this.paymentType = 3; break;
              case 3: this.paymentType = 4; break;
              case 4: this.paymentType = 5; break;
              case 5: this.paymentType = 6; break;
              default: this.paymentType = 1; break;
            }


          } else {
            this.paymentType = data.payload.visit.paymentType;
          }

          //this.loadPrices(data.payload.visit.priceSetId);

          if (this.id > 0) {
            this.visitsService.Spendings(this.id).subscribe(response => this.totalSpendings = response.total);
          }

          this.allowAnonymous = data.payload.visit.allowAnonymous;

          if (this.allowAnonymous) this.isAnonymousControl.patchValue(data.payload.visit.isAnonymous);
          else this.isAnonymousControl.patchValue(false);

          this._permissionsCheck$.next();
        });

    this.companiesService.Companies({ Type: 6 })
      .subscribe((response: Company[]) => {
        this.customerCompanies = response;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  addService = (services: SelectedService[]) => this._servicesSelected$.next(services);

  /**
   * Получить цену для услуги
   * @param serviceId
   */
  priceFor = (serviceId: number) => this.grossPriceFor(serviceId);
  /**
   * Цена услуги без учата скидок
   * @param serviceId
   */
  grossPriceFor = (serviceId: number) => this._prices[serviceId] || 0;

  recalculateRelevantPriceSets(insuranceCompanyId: number, hasGuaranteeLetter: boolean): PriceSet[] {
    let priceSets: PriceSet[] = [];
    if (insuranceCompanyId > 0) {
      priceSets = this.priceSets.filter(x => {
        if (!x.companyId && !x.insuranceCompanyId && hasGuaranteeLetter) return true;
        if (!x.companyId && x.insuranceCompanyId === insuranceCompanyId) return true;
        if (x.companyId === this.companyId && !x.insuranceCompanyId && hasGuaranteeLetter) return true;
        if (x.companyId === this.companyId && x.insuranceCompanyId === insuranceCompanyId) return true;

        return false;
      });
    } else {
      priceSets = this.priceSets.filter(x => !x.insuranceCompanyId && (!x.companyId || x.companyId === this.companyId));
    }

    return priceSets;
  }

  /**
   * Проверить существование цены для услуги
   * @param serviceId
   */
  priceExistsFor = (serviceId: number) => this._prices[serviceId] !== undefined;

  onPaymentTypeChange(type: 1 | 2 | 3 | 4 | 5 | 6): void {
    if (this.paymentType === 4 && type !== 4) {
      this.dmsInfoChanged({ certificate: '', insuranceCompanyId: undefined, guaranteeNumber: '', guaranteeDate: '' });
    }
    this.paymentType = type;
    this._permissionsCheck$.next();
  }

  onPaymentChange(value: number) {
    this.payment = value;
    this._permissionsCheck$.next();
  }

  /**
   * Обновить полную стоимость посещения
   * */
  private updateFinances(): void {
    const sum: number = this.selectedServices.filter(x => !x.refundId || x.refundStatus !== 1)
      .map(x => this.priceFor(x.serviceId) * x.quantity)
      .reduce((x, y) => x + y, 0);

    this.total = sum * (100 - (this.discountValue.amount || 0)) / 100;
    if (this.paymentType !== 1) this.payment = this.total;

    this._permissionsCheck$.next();
  }

  /** Удалить выбранные услуги */
  deleteFromSelected(ids: number[]) {

    const removeCandidates = this.selectedServices.filter(x => ids.includes(x.id));

    for (const item of removeCandidates.filter(x => x.orderSent)) {
      this.toastrService.warning(`Не удалось удалить услугу ${item.name}: заявка по услуге уже отправлена`);
    }

    if (removeCandidates.some(x => x.orderSent)) return;

    this.selectedServices = this.selectedServices.filter(x => !ids.includes(x.id));

    const serviceids = this.selectedServices.map(x => x.serviceId);

    for (const option of this.options) {
      option.services = option.services.filter((x) => serviceids.includes(x));
    }

    this.options = this.options.filter(x => x.services.length > 0);

    this.updateFinances();
    this._serviceValidityCheck$.next();
  }

  //суммарный итог для контейнеров по локусам
  containersSummary(): SummaryLocus[] {
    const containers: SelectedLocusContainer[] = [];

    for (const service of this.selectedServices) {
      containers.push(...service.containers);
    }

    const result: { [locusName: number]: { locusName: string, containers: { [containerId: number]: { name: string, total: number } } } } = {};

    for (const container of containers) {
      if (!result[container.locusId]) {
        result[container.locusId] = {
          locusName: container.locusName,
          containers: {}
        };
      }

      if (!result[container.locusId].containers[container.containerId]) {
        result[container.locusId].containers[container.containerId] = { name: container.containerName, total: 0 };
      }

      result[container.locusId].containers[container.containerId].total++;
    }

    const summary: SummaryLocus[] = [];

    for (const value of Object.values(result)) {
      const locus: SummaryLocus = { name: value.locusName, containers: [] };
      locus.containers = Object.values(value.containers).map((x: { name: string, total: number }): SummaryContainer => ({ name: x.name, total: x.total }));
      summary.push(locus);
    }

    return summary;
  }

  async openSelectContainerComponent(selected: SelectedService) {
    if (!selected.kdlCode) return;

    const dob = this.patientDataForm.get("dob").value && this.patientDataForm.get("dob").value.isValid() ?
      this.patientDataForm.get("dob").value.format("DD.MM.YYYY") : ""

    const details = await this.servicesService.DetailsAsync({
      ServiceIds: [selected.serviceId],
      Date: this.dateControl.value.format("DD.MM.YYYY"),
      Dob: dob,
      CompanyId: this.companyId
    }).toPromise();

    const modalRef = this.modal.open(ServiceDetailsModalComponent, { size: 'lg', centered: true, backdrop: 'static' });
    const componentRef: ServiceDetailsModalComponent = modalRef.componentInstance;

    componentRef.services = [selected];
    componentRef.details = details;

    componentRef.onCancel.subscribe(() => {
      modalRef.close();
    });

    componentRef.onConfirm.subscribe((items: SelectedServiceDetails[]) => {
      const item = items.find(x => x.id === selected.id);
      if (!!item) {
        selected.containers = [...item.selectedLocusContainers];
      }

      modalRef.close();
      this._serviceValidityCheck$.next();
    });

    return;




    this.locusesService.Find({ Date: this.date, ServiceId: selected.serviceId, Page: 1, Size: 0 })
      .pipe(
        switchMap((response: LocusesSearchResponse): Observable<SelectedLocusContainer[]> => {
          const options: NgbModalOptions = { size: 'lg', backdrop: 'static', centered: true };
          const modalRef: NgbModalRef = this.modal.open(VisitsContainerSelectModalComponent, options);
          const componentRef: VisitsContainerSelectModalComponent = modalRef.componentInstance;

          componentRef.serviceName = selected.name;
          componentRef.selectedService = selected;
          componentRef.locuses = response.items;
          componentRef.selectedContainers = selected.containers;

          return from(modalRef.result);
        })
      )
      .subscribe(
        (result: SelectedLocusContainer[]): void => {
          selected.containers = result;
          this._serviceValidityCheck$.next();
        },
        (): void => { }
      );

  }

  async openSelectPerformerComponent(selected: SelectedService) {
    if (!selected.specialityId) return;

    const dob = this.patientDataForm.get("dob").value && this.patientDataForm.get("dob").value.isValid() ?
      this.patientDataForm.get("dob").value.format("DD.MM.YYYY") : ""

    const details = await this.servicesService.DetailsAsync({
      ServiceIds: [selected.serviceId],
      Date: this.dateControl.value.format("DD.MM.YYYY"),
      Dob: dob,
      CompanyId: this.companyId
    }).toPromise();

    const modalRef = this.modal.open(ServiceDetailsModalComponent, { size: 'lg', centered: true, backdrop: 'static' });
    const componentRef: ServiceDetailsModalComponent = modalRef.componentInstance;

    componentRef.services = [selected];
    componentRef.details = details;

    componentRef.onCancel.subscribe(() => {
      modalRef.close();
    });

    componentRef.onConfirm.subscribe((items: SelectedServiceDetails[]) => {
      const item = items.find(x => x.id === selected.id);
      if (!!item && item.performerId > 0) {
        selected.performer = item.performers.find(x => x.id === item.performerId);
      }

      modalRef.close();
      this._serviceValidityCheck$.next();
    });

    return;

    this.servicesService.Performers({
      id: selected.serviceId,
      CompanyId: this.companyId,
      VisitDate: this.dateControl.value.format("DD.MM.YYYY"),
      Gender: this.patientDataForm.get("gender").value,
      Dob: (this.patientDataForm.get("dob").value && this.patientDataForm.get("dob").value.isValid()) ? this.patientDataForm.get("dob").value.format("DD.MM.YYYY") : ""
    })
      .pipe(
        switchMap((response: PerformersResponse): Observable<Performer> => {
          const options: NgbModalOptions = { size: 'lg', backdrop: 'static', centered: true, windowClass: "modal-default-size" };
          const modalRef: NgbModalRef = this.modal.open(VisitsPerformerSelectModalComponent, options);
          const componentRef: VisitsPerformerSelectModalComponent = modalRef.componentInstance;

          componentRef.specialityId = selected.specialityId;
          componentRef.specialityName = selected.specialityName;
          componentRef.serviceName = selected.name;

          componentRef.performers = response.items;
          componentRef.selectedPerformerId = selected.performer ? selected.performer.id : undefined;

          return from(modalRef.result);
        }),
        filter(x => !!x)
      )
      .subscribe(
        (performer: Performer): void => {
          selected.performer = {
            id: performer.id,
            name: performer.name,
            minAge: performer.minAge,
            maxAge: performer.maxAge
          };

          this._serviceValidityCheck$.next();
        },
        (): void => { }
      );
  }

  searchReprsentativeByName(query: string) {
    if (!this.canSearchRepresentative) return;

    const modalRef: NgbModalRef = this.openSearchModal(query);
    const componentRef: SelectClientModalComponent = modalRef.componentInstance;

    componentRef.onSelect
      .pipe(
        switchMap((value: PatientSearchResult): Observable<Patient> => {
          if (!value.id && value.personId > 0) {

            const patient: Patient = {
              dob: value.dob,
              firstname: value.firstname,
              gender: value.gender,
              lastname: value.lastname,
              middlename: value.middlename,
              personId: value.personId,
              snils: value.snils,
            };

            return of(patient);
          }

          return this.patientsService.Patient(value.id);
        })
      )
      .subscribe(
        (result: Patient): void => {
          modalRef.close();

          const representative: Patient = {
            id: result.personId,
            firstname: result.firstname,
            middlename: result.middlename,
            lastname: result.lastname,
            phone: result.phone,
            passportNumber: result.passportNumber,
            issuer: result.issuer,
            issued: result.issued,
            issuerCode: result.issuerCode
          }

          this._representativeSubject$.next(representative);
        },
        (): void => { }
      );
  }

  serarchPatientByName(query: string) {
    if (!this.canSearchPatient) return;

    const modalRef: NgbModalRef = this.openSearchModal(query);
    const componentRef: SelectClientModalComponent = modalRef.componentInstance;

    componentRef.onSelect
      .pipe(
        switchMap((value: PatientSearchResult): Observable<Patient> => {

          if (!value.id && value.personId > 0) {
            const patient: Patient = {
              dob: value.dob,
              firstname: value.firstname,
              gender: value.gender,
              lastname: value.lastname,
              middlename: value.middlename,
              personId: value.personId,
              snils: value.snils,
            };

            return of(patient);
          }

          return this.patientsService.Patient(value.id);
        })
      )
      .subscribe(
        (patient: Patient): void => {
          modalRef.close();

          this.applyPatient(patient);
        },
      );
  }

  private applyPatient(patient: Patient): void {
    this._patientSubject$.next(patient);

    const date = this.dateControl.value.format("DD.MM.YYYY")

    if (patient && patient.id > 0) {
      this._discounts$ = this.patientsService.Discounts({ id: patient.id, Date: date, CompanyId: this.companyId })
        .pipe(tap(() => this.discountValue = { id: patient.defaultDiscountId, reason: patient.defaultDiscountReason, amount: patient.defaultDiscountValue }));
    } else {
      this._discounts$ = this.discountsService.CommonDiscounts({ Date: date, CompanyId: this.companyId });
    }
  }

  async searchPatientByPhone(): Promise<void> {
    if (!this.canSearchPatient) return;

    const phone: string = this.contactsFormGroup.get("phone").value;

    const searchPatientsResponse = await this.patientsService.Search({ Page: 1, Size: 10, Search: phone }).toPromise();

    if (searchPatientsResponse && searchPatientsResponse.items.length === 1) {
      const patient = await this.patientsService.Patient(searchPatientsResponse.items[0].id).toPromise();
      this.applyPatient(patient);
      return;
    }

    const options: NgbModalOptions = { size: 'lg', backdrop: 'static', centered: true, windowClass: "modal-default-size" };
    const modalRef: NgbModalRef = this.modal.open(SelectClientModalComponent, options);
    const componentRef: SelectClientModalComponent = modalRef.componentInstance;

    componentRef.searchQuery = phone;
    componentRef.onClose.subscribe(() => modalRef.close());

    modalRef.componentInstance.onSelect.subscribe(async (value: PatientSearchResult): Promise<void> => {
      const patient = await this.patientsService.Patient(value.id).toPromise();
      this.applyPatient(patient);
      modalRef.close();
    });

  }

  openSearchModal(searchQuery: string): NgbModalRef {
    const options: NgbModalOptions = { size: 'lg', backdrop: 'static', centered: true, windowClass: "modal-default-size" };

    const modalRef: NgbModalRef = this.modal.open(SelectClientModalComponent, options);
    const componentRef: SelectClientModalComponent = modalRef.componentInstance;

    componentRef.searchQuery = searchQuery;
    componentRef.selectedPatientId = this.patientId;

    componentRef.onClose.subscribe(() => modalRef.close());

    return modalRef;
  }

  clearPatient() {
    if (!this.canSearchPatient) return;

    this._patientSubject$.next(null);
    this._contactsSubject$.next({ phone: "", email: "" });
    this.addressFormGroup.patchValue({ common: "" });

    this._discounts$ = this.discountsService.CommonDiscounts({ Date: this.dateControl.value.format("DD.MM.YYYY"), CompanyId: this.companyId });
  }

  clearRepresentative() {
    if (!this.canSearchRepresentative) return;

    this._representativeSubject$.next(null);
    this._contactsSubject$.next({ phone: "", email: "" });
    this.addressFormGroup.patchValue({ common: "" });
  }

  private clearPatientForm(): void {
    const patch: any = {
      gotInfoFrom: undefined,
      cardNo: undefined,

      lastName: "",
      firstName: "",
      middleName: "",

      gender: undefined,
      documentNo: "",

      documentIssuedBy: "",
      phone: undefined,
      mail: "",
      address: "",
      hasRepresentative: false,
      dob: undefined,
      documentDate: undefined,

      payer: false,
      repFirstname: "",
      repMiddlename: "",
      repLastname: "",
      repPhone: "",
      repPassportNumber: "",
      repIssuer: "",
      repIssued: undefined,
      inn: ""
    };

    this.patientDataForm.patchValue(patch);
  }

  private async create(): Promise<void> {

    const services: AddService[] = this.selectedServices.map((x: SelectedService): AddService => {
      const service: AddService = {
        serviceId: x.serviceId,
        performerId: (x.performer) ? x.performer.id : undefined,
        quantity: 1,
        prescriptionId: x.prescriptionId,
        containers: x.containers.map((c: SelectedLocusContainer): AddLocusContainer => {
          const lc: AddLocusContainer = {
            containerId: c.containerId,
            locusId: c.locusId
          };

          return lc;
        })
      };

      return service;
    });

    const patientFormValue = this.patientDataForm.getRawValue();

    const issued: moment.Moment = patientFormValue.documentDate;
    const dob: moment.Moment = patientFormValue.dob;
    const date: moment.Moment = this.dateControl.value;
    const time: { hour: number; minute: number; } = this.timeControl.value;

    const dateTime = date.clone().hour(time.hour).minute(time.minute).format('DD.MM.YYYY HH:mm');

    const representativeFormValue: RepresentativeFormValue = this.representativeFormGroup.getRawValue();
    const contactsFormValue: ContactsFormValue = this.contactsFormGroup.getRawValue();
    const address: { common: string } = this.addressFormGroup.getRawValue();

    const patientNumber: number = this.personalNumberControl.value;

    const request: AddVisitModel = {
      patientId: this.patientId,
      personId: this.personId,
      patientFirstname: patientFormValue.firstName,
      patientMiddlename: patientFormValue.middleName,
      patientLastname: patientFormValue.lastName,
      patientDocumentNumber: patientFormValue.documentNo,
      patientIssuer: patientFormValue.documentIssuedBy,
      patientIssued: issued && issued.isValid() ? issued.format("DD.MM.YYYY") : "",
      patientDob: dob && dob.isValid() ? dob.format("DD.MM.YYYY") : "",
      phone: contactsFormValue.phone,
      email: contactsFormValue.email,
      patientGender: patientFormValue.gender,
      patientNumber: patientNumber,

      hasRepresentative: this.hasRepresentativeControl.value,
      payer: representativeFormValue.payer === true,
      representativeId: this.representativeId,
      representativeFirstname: representativeFormValue.firstname,
      representativeMiddlename: representativeFormValue.middlename,
      representativeLastname: representativeFormValue.lastname,
      representativeIssuer: representativeFormValue.issuer,
      representativeIssued: (representativeFormValue.issued && representativeFormValue.issued.isValid()) ?
        representativeFormValue.issued.format("DD.MM.YYYY") : "",
      representativePassportNumber: representativeFormValue.passportNumber,

      partnerId: patientFormValue.headedByDoctor,
      referralCompanyId: patientFormValue.headedByOrg,

      customerChannelId: patientFormValue.gotInfoFrom,
      priceSetId: this.priceSetControl.value,
      registratorId: this.registratorControl.value,
      colormarkId: patientFormValue.label,

      date: dateTime,

      payment: this.payment || 0,
      paymentType: this.paymentType,

      primary: this.primary,

      address: address.common,
      snils: this.omsData.snils,

      visitsResult: this.omsData.result,
      citizenship: this.omsData.citizenship,
      disability: this.omsData.disability,
      socialStatus: this.omsData.socialStatus,

      discountId: this.discountValue.id > 0 ? this.discountValue.id : undefined,
      discountReason: this.discountValue.reason,
      discountAmount: this.discountValue.amount,

      dmsCertificate: this.dmsInfo.certificate,
      dmsInsuranceCompanyId: this.dmsInfo.insuranceCompanyId,
      guaranteeLetterNumber: this.dmsInfo.guaranteeNumber,
      guaranteeLetterDate: this.dmsInfo.guaranteeDate,

      services: services,
      documents: this.documents.map((x: PersonDocument): number => x.id),

      options: this.options.map(x => ({ tag: x.tag, type: x.type, value: x.value })),
      marks: this.marks.map(x => ({ id: x.id, colorMarkId: x.colorMarkId, description: x.description })),

      inn: patientFormValue.inn,
      patientIssuerCode: patientFormValue.issuerCode,
      representativeIssuerCode: representativeFormValue.issuerCode,

      customerCompanyId: patientFormValue.customerCompanyId,

      isAnonymous: this.isAnonymous
    };

    const response = await this.visitsService.Create(request).toPromise();

    this.id = response.id;
  }

  private async update(): Promise<void> {
    const value: any = this.patientDataForm.getRawValue();
    const contacts: { phone: string; email: string } = this.contactsFormGroup.getRawValue();
    const address: { common: string } = this.addressFormGroup.getRawValue();

    const date: moment.Moment = this.patientDataForm.get("cardDate").value;
    const time: { hour: number; minute: number; } = this.timeControl.value;
    const issued: moment.Moment = value.documentDate;
    const dob: moment.Moment = value.dob;

    const dateTime = date.clone().hour(time.hour).minute(time.minute).format('DD.MM.YYYY HH:mm');

    const services: EditSelectedServiceModel[] = this.selectedServices
      .map(
        (x: SelectedService): EditSelectedServiceModel => {
          return {
            id: x.id,
            serviceId: x.serviceId,
            performerId: x.performer ? x.performer.id : undefined,
            quantity: x.quantity,
            containers: x.containers.map((s: SelectedLocusContainer): EditLocusContainer => ({ id: s.id, locusId: s.locusId, containerId: s.containerId })),
            prescriptionId: x.prescriptionId
          };
        });

    const repIssued: string = (value.representative.issued && value.representative.issued.isValid()) ?
      value.representative.issued.format("DD.MM.YYYY") : "";

    const request: EditVisitModel = {
      patientFirstname: value.firstName,
      patientMiddlename: value.middleName,
      patientLastname: value.lastName,
      gender: value.gender,
      patientDob: dob && dob.isValid() ? dob.format("DD.MM.YYYY") : "",
      personalNumber: this.personalNumberControl.value,

      hasRepresentative: this.hasRepresentativeControl.value,
      representativeId: this.representativeId,

      payer: value.representative.payer === true,

      representativeFirstname: value.representative.firstname,
      representativeMiddlename: value.representative.middlename,
      representativeLastname: value.representative.lastname,
      representativePassportIssued: repIssued,
      representativePassportIssuer: value.representative.issuer,
      representativePassportNumber: value.representative.passportNumber,

      date: dateTime,
      partnerId: value.headedByDoctor,
      referralCompanyId: value.headedByOrg,

      channelId: value.gotInfoFrom,
      registratorId: this.registratorControl.value,
      labelId: value.label,

      passportNumber: value.documentNo,
      passportIssuer: value.documentIssuedBy,
      passportIssued: issued && issued.isValid() ? issued.format("DD.MM.YYYY") : "",

      address: address.common,
      email: contacts.email,
      phone: contacts.phone,

      socialStatus: this.omsData.socialStatus,
      citizenship: this.omsData.citizenship,
      disability: this.omsData.disability,
      snils: this.omsData.snils,
      result: this.omsData.result,

      priceSetId: this.priceSetControl.value,

      services: services,

      payment: this.payment || 0,
      paymentType: this.paymentType,

      discountId: this.discountValue.id > 0 ? this.discountValue.id : undefined,
      discountAmount: this.discountValue.amount,
      discountReason: this.discountValue.reason,

      options: this.options.map(x => ({ tag: x.tag, type: x.type, value: x.value })),
      marks: this.marks.map(x => ({ id: x.id, colorMarkId: x.colorMarkId, description: x.description })),

      dmsCertificate: this.dmsInfo.certificate,
      dmsInsuranceCompanyId: this.dmsInfo.insuranceCompanyId,
      guaranteeLetterNumber: this.dmsInfo.guaranteeNumber,
      guaranteeLetterDate: this.dmsInfo.guaranteeDate,

      inn: value.inn,
      passportIssuerCode: value.issuerCode,
      representativePassportIssuerCode: value.representative.issuerCode,

      customerCompanyId: value.customerComapntId,

      isAnonymous: this.isAnonymous
    };

    await this.visitsService.Update({ id: this.id, request: request }).toPromise();

    this.selectedServices = await this.visitsService.Services(this.id).toPromise();

    this._permissionsCheck$.next();
  }

  /** true if can proceed */
  private async addOrUpdate(): Promise<boolean> {
    this._permissionsCheck$.next();

    this.setFormTouched();
    if (!this.isValid()) return false;

    if (!await this.checkDuplicated()) return false;

    try {
      if (this.exists) await this.update();
      else await this.create();

      return true;
    }
    catch (error) {

      if (error instanceof HttpErrorResponse) {
        const errorResponse = error as HttpErrorResponse;
        if (errorResponse.status === 500) {
          this.toastrService.error("Не удалось сохранить посещение", "Ошибка");
        } else if (errorResponse.status === 400) {
          for (let error of errorResponse.error.errors) {
            this.toastrService.warning(error.message, "Ошибка");
          }
        }
      }

      return false;
    }
  }

  /** Возвращает true если можно продолжать */
  private async checkDuplicated(): Promise<boolean> {
    if (this.patientId > 0) return true;

    const value: any = this.patientDataForm.getRawValue();
    const contacts: { phone: string } = this.contactsFormGroup.getRawValue();

    try {
      const duplicate = await this.peopleService.DuplicatesSearch({
        FirstName: value.firstName,
        LastName: value.lastName,
        MiddleName: value.middleName,
        BirthDate: value.dob && value.dob.isValid() ? value.dob.format("DD.MM.YYYY") : "",
        Gender: value.gender,
        Phone: contacts.phone
      }).toPromise();

      if (!duplicate.duplicateExists) return true;

      if (!await this.openDuplicateModal(duplicate)) return false;

      this.personId = duplicate.id;
      return true;

    } catch (e) {
      return false;
    }
  }

  async save(): Promise<void> {
    if (this.processing) return;

    this.processing = true;

    this._permissionsCheck$.next();

    if (!await this.addOrUpdate()) {
      this.processing = false;
      return;
    }

    this.toastrService.success("Посещение сохранено", "Успешно");

    if (this._transient) this.router.navigate(["visits", this.id]);
    else this.router.navigate([".."], { relativeTo: this.activatedRoute });
  }

  setFormTouched() {
    Object.entries(this.patientDataForm.controls).forEach(x => x[1].markAsTouched());
    Object.entries(this.representativeFormGroup.controls).forEach(x => x[1].markAsTouched());
    Object.entries(this.contactsFormGroup.controls).forEach(x => x[1].markAsTouched());

    this.registratorControl.markAsTouched();
    this.dateControl.markAsTouched();

    this.contactsFormGroup.get("phone").markAsTouched();

    this.submittedOnce = true;
    this.patientDataForm.markAsTouched({ onlySelf: false });
  }

  isValid = (): boolean => !this.invalidPatient && !this.invalidServices;

  validateService(service: SelectedService) {
    if (!this.priceExistsFor(service.serviceId)) return false;
    if (!!service.specialityId && !service.performer) return false;

    const dob: moment.Moment = this.patientDataForm.controls['dob'].value;
    const date: moment.Moment = this.dateControl.value;

    console.info({ dob, date, performer: service.performer })

    if (service.performer && !!dob && dob.isValid) {
      if (service.performer.maxAge && dob.isBefore(date.clone().add(-service.performer.maxAge, 'year'))) {
        return false;
      }

      if (service.performer.minAge && dob.isAfter(date.clone().add(-service.performer.minAge, 'year'))) {
        return false;
      }
    }

    if (!!service.kdlCode && !service.isExpress) {
      if (!service.containers || service.containers.length === 0) return false;
    }

    return true;
  }

  // TODO: add socket interactions here
  async printReceipt(): Promise<void> {
    if (this.processing) return;

    this._permissionsCheck$.next();

    if (!this.canPrintReceipt) return;

    this.processing = true;

    if (!await this.addOrUpdate()) {
      this.processing = false;
      return;
    }

    this.toastrService.success("Печать чека начата", "Посещение сохранено");

    try {
      await this.paymentsService.CreatePaymentAsync(this.id).toPromise();

      if (this._transient) {
        this.router.navigate(["visits", this.id]);
      } else {
        for (const item of this.selectedServices) {
          if (!item.receiptId) {
            item.receiptId = 1;
            item.receiptStatus = 0;
          }
        }
        this._permissionsCheck$.next();
      }

      const data = await this.visitReportsService.PrintVisitDocuments({ visitId: this.id, type: 'pdf', asCopy: false }).toPromise();
      if (data.content !== null) {
        this.printReport(data.content, data.reportType);
      }
    }
    catch (error) {
      if (error instanceof HttpErrorResponse) {
        const response = error as HttpErrorResponse;

        if (response.status === 400) {
          for (const error of response.error.errors) {

            let message = '';

            switch (error.status) {
              case 1: message = 'Пользователь не найден'; break;
              case 2: message = 'Посещение не найдено'; break;
              case 3: message = 'Нет услуг для печати чека'; break;
              case 4: message = 'Для одной или нескольких услуг не указана цена'; break;
              case 5: message = 'Недопустимая сумма'; break;
              case 6: message = 'Ошибка печати чека'; break;
              case 7: message = 'Для выполнения операции отсутствуют необходимые разрешения'; break;
              case 8: message = 'Печать чека для посещения с долгом запрещена'; break;
              case 9: message = 'Печать чека из другой организации запрещена'; break;
              case 10: message = 'Печать чека для выбранного типа оплаты не поддерживается'; break;
              case 11: message = 'Система налогообложения по умолчанию не указана'; break;
              case 12: message = "Устройство печати чека не найдено"; break;
              case 13: message = "Неправильный тип устройства печати чека"; break;
              case 14: message = "Устройство печати чека зарегистрировано в другой организации"; break;
              case 18: message = "Печать чека не завешена. Пожалуйста, дождитесь окончания печати и повторите попытку"; break;

              default: message = 'Внутренняя ошибка сервера'; break;
            }

            this.toastrService.error(message, "Ошибка при печати чека");
          }
        } else {
          this.toastrService.error("Не удалось напечатать чек", "Ошибка");
        }

        if (this._transient) {
          this.router.navigate(["visits", this.id]);
        }
      }
    }
    finally {
      this.processing = false;
    }

  }

  async printAgreement(): Promise<void> {
    if (this.processing) return;

    this._permissionsCheck$.next();

    if (!this.canPrintAgreement) return;

    this.processing = true;

    if (!await this.addOrUpdate()) {
      this.processing = false;
      return;
    }

    this.toastrService.success("Посещение сохранено", "Успешно");

    try {
      this.toastrService.success("Документ формируется...", "Печать");
      const data = await this.visitReportsService.PrintAgreementReport({ visitId: this.id, type: 'pdf', asCopy: true }).toPromise();
      this.printReport(data.content, data.reportType);
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        const httpErrorResponse = e as HttpErrorResponse;

        if (httpErrorResponse.status === 400) {
          this.toastrService.warning("Нет услуг, для которых необходима печать согласия", "Нет услуг для печати")
        } else {
          this.toastrService.warning("Не удалось напечатать согласие", "Ошибка");
        }
      } else {
        this.toastrService.warning("Не удалось напечатать согласие", "Ошибка");
      }
    } finally {
      this.processing = false;

      if (this._transient) {
        this.router.navigate(["visits", this.id]);
      } else {
        this.loadDocuments();

      }
    }
  }

  async printContract(): Promise<void> {
    if (this.processing) return;

    this._permissionsCheck$.next();

    if (!this.canPrintContract) return;

    this.processing = true;

    if (!await this.addOrUpdate()) {
      this.processing = false;
      return;
    }

    this.toastrService.success("Посещение сохранено", "Успешно");

    try {
      this.toastrService.success("Документ формируется...", "Печать");
      const data = await this.visitReportsService.PrintVisitContract({ visitId: this.id, type: 'pdf' }).toPromise();
      this.printReport(data.content, data.reportType);
    }
    catch (error) {
      this.toastrService.warning("Не удалось напечатать договор", "Ошибка");
    }
    finally {
      this.processing = false;

      if (this._transient) {
        this.router.navigate(["visits", this.id]);
      } else {
        this.loadDocuments();
      }
    }
  }

  async printAgreements(): Promise<void> {
    if (this.processing) return;

    this._permissionsCheck$.next();

    if (!this.canPrintAgreement) return;

    this.processing = true;

    if (!await this.addOrUpdate()) {
      this.processing = false;
      return;
    }

    this.toastrService.success("Посещение сохранено", "Успешно");

    try {
      this.toastrService.success("Документ формируется...", "Печать");
      const data = await this.visitReportsService.PrintAgreementReportGroup({ type: 'pdf', visitId: this.id, asCopy: true }).toPromise();
      this.printReport(data.content, data.reportType);
    }
    catch (e) {
      if (e instanceof HttpErrorResponse) {
        const httpErrorResponse = e as HttpErrorResponse;

        if (httpErrorResponse.status === 400) {
          this.toastrService.warning("Нет документов для печати", "Печать")
        } else {
          this.toastrService.warning("Не удалось напечатать согласие", "Ошибка");
        }
      } else {
        this.toastrService.warning("Не удалось напечатать согласие", "Ошибка");
      }
    }
    finally {
      this.processing = false;

      if (this._transient) {
        this.router.navigate(["visits", this.id]);
      } else {
        this.loadDocuments();
      }
    }
  }

  async printInvoice(): Promise<void> {
    if (this.processing) return;

    this._permissionsCheck$.next();

    if (!this.canPrintInvoice) return;

    this.processing = true;

    if (!await this.addOrUpdate()) {
      this.processing = false;
      return;
    }

    this.toastrService.success("Посещение сохранено", "Успешно");

    try {
      this.toastrService.success("Документ формируется...", "Печать");
      const data = await this.visitReportsService.PrintInvoiceReportsGroup({ visitId: this.id, type: 'pdf' }).toPromise();
      this.printReport(data.content, data.reportType);
    }
    catch (error) {
      console.log(error);

      this.toastrService.warning("Не удалось напечатать счет");
    }
    finally {
      this.processing = false;

      if (this._transient) {
        this.router.navigate(["visits", this.id]);
      } else {
        this.loadDocuments();
      }
    }
  }

  async printConsent(): Promise<void> {
    if (this.processing) return;

    this._permissionsCheck$.next();

    if (!this.canPrintConsent) return;

    this.processing = true;

    if (!await this.addOrUpdate()) {
      this.processing = false;
      return;
    }

    this.toastrService.success("Посещение сохранено", "Успешно");

    try {
      this.toastrService.success("Документ формируется...", "Печать");
      const data = await this.visitReportsService.PrintConsentReportsGroup({ visitId: this.id, type: 'pdf', asCopy: true }).toPromise();
      this.printReport(data.content, data.reportType);
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        const httpErrorResponse = e as HttpErrorResponse;

        if (httpErrorResponse.status === 400) {
          this.toastrService.warning("Нет услуг, для которых необходима печать согласия", "Нет услуг для печати")
        } else {
          this.toastrService.warning("Не удалось напечатать согласие", "Ошибка");
        }
      } else {
        this.toastrService.warning("Не удалось напечатать согласие", "Ошибка");
      }
    } finally {
      this.processing = false;

      if (this._transient) {
        this.router.navigate(["visits", this.id]);
      } else {
        this.loadDocuments();
      }
    }
  }

  async printPreliminaryInvoice() {
    try {
      this.toastrService.success("Документ формируется...", "Печать");

      const request = {
        discount: this.discount,
        priceSetId: this.priceSetControl.value,
        services: this.selectedServices.map(x => ({ serviceId: x.serviceId, quantity: x.quantity }))
      };

      const data = await this.visitReportsService.BuildPreliminaryInvoiceAsync(request).toPromise();
      this.printReport(data.content, data.reportType);
    } catch (e) {
      this.toastrService.warning("Не удалось напечатать расчет стоимости", "Ошибка");

    }
  }

  private printReport(content: string, type: string) {
    const byteCharacters = atob(content);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const url = URL.createObjectURL(new Blob(byteArrays, { type: type }));

    const frame: HTMLIFrameElement = document.createElement("iframe");

    frame.style.display = "none";
    frame.src = url;
    frame.onload = () => URL.revokeObjectURL(url);

    document.body.append(frame);
    frame.contentWindow.print();
  }

  async refund(ids: number[]): Promise<void> {
    if (!this.canRefundService) return;

    const reasons = await this.refundReasonsService.AllAsync({ IncludeArchived: false }).toPromise();

    const options: NgbModalOptions = { backdrop: 'static', size: 'lg', centered: true };
    const modalRef = this.modal.open(RefundModalComponent, options);
    const componentRef: RefundModalComponent = modalRef.componentInstance;

    componentRef.reasons = reasons;

    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe((reason: RefundReason): void => {

      componentRef.processing = true;

      this.refundsService.CreateRefundAsync({ id: this.id, request: { ids: ids, refundReasonId: reason.id } })
        .subscribe(
          (): void => {
            for (const id of ids) {
              const service: SelectedService = this.selectedServices.find((x: SelectedService): boolean => x.id === id);
              if (service) {
                //service.refundId = 1;
                //service.refundStatus = 0;
                service.refundReasonId = reason.id;
                service.refundReasonName = reason.description;
              }
            }

            this.toastrService.success(`Печать чека возврата начата. Причина возврата - ${reason.description}`, 'Возврат оформлен');

            modalRef.close();

            this._permissionsCheck$.next();
          },
          (response: HttpErrorResponse): void => {
            componentRef.processing = false;

            if (response.status === 400) {
              for (const error of response.error.errors) {
                let message = '';

                // todo: move this in some storage
                switch (error.status) {
                  case 1: message = "Не выбраны услуги для оформления возврата"; break;
                  case 2: message = "Пользователь не найден"; break;
                  case 3: message = "Для выполнения операции отсутствуют необходимые разрешения"; break;
                  case 4: message = "Устройство печати чека возврата не найдено"; break;
                  case 5: message = "Неправильный тип устройства печати чека возврата"; break;
                  case 6: message = "Устройство печати чека возврата зарегистрировано в другой организации"; break;
                  case 7: message = "Указана неизвестная причина возврата"; break;
                  case 8: message = "Посещение не найдено"; break;
                  case 9: message = "Система налогообложения по умолчанию не указана"; break;
                  case 10: message = "Возврат для указанного типа оплаты не поддерживается"; break;
                  case 11: message = "Для одной или нескольких услуг не указана цена"; break;
                  case 13: message = "Печать чека для одной или нескольких услуг не завершена"; break;

                  default: message = 'Внутренняя ошибка сервера'; break;
                }

                this.toastrService.warning(message, "Ошибка");
              }
              return;
            }

            this.toastrService.error("Не удалось напечатать чек", "Ошибка");
          });
    });

  }

  async addFile(file: File) {
    if (!this.personId) return;

    const document: PersonDocument = {
      status: 1,
      date: moment().valueOf(),
      title: file.name,
      authorShortName: this.userStorage.profile.userName,
      documentType: 'Uploads'
    };

    this.documents.unshift(document);

    try {
      const response = await this.personDocumentsService.UploadAsync({
        personId: this.personId,
        Document: file
      }).toPromise();

      document.status = 2;

      document.documentHash = response.hash;

      this.toastrService.success(`Файл ${file.name} загружен`, 'Успешно');
    } catch (e) {
      this.toastrService.error(`Не удалось загрузить документ ${file.name}`, 'Ошибка');

      document.status = 3;
    }
  }

  private async loadDocuments() {
    if (!this.patientId) return;

    this.loadingDocuments = true;

    this.documents = [];
    try {
      this.documents = await this.personDocumentsService.DocumentsAsync(this.personId).toPromise();
    } catch (e) {
      this.toastrService.error('Не удалось загрузить документы', 'Ошибка');
    }

    this.loadingDocuments = false;
  }

  private loadPrescriptions(): void {
    if (!this.patientId) return;

    this.patientPrescriptionsService.PrescriptedManipulationsAsync(this.patientId)
      .subscribe((prescriptions: PatientPrescriptedManipulation[]) => this._prescriptionsSource$.next(prescriptions));
  }

  loadMarks() {
    if (this.personId > 0) {
      this.personMarksService.PersonMarksAsync(this.personId)
        .subscribe((response: PersonMark[]) => this.marks = response);
    } else {
      this.marks = [];
    }
  }

  async removeDocument(document: Document) {
    const confirmed = await this.confirmationService.open({ message: `Документ ${document.title} будет удален. Продолжить?` });

    if (!confirmed) return;

    try {
      await this.personDocumentsService.RemoveAsync({ personId: this.personId, hash: document.documentHash }).toPromise();

      this.documents = this.documents.filter(x => x.documentHash !== document.documentHash);

      this.toastrService.success(`Документ ${document.title} удален`, 'Успешно');
    }
    catch (e) {
      this.toastrService.error(`Не удалось удалить документ ${document.title}`, 'Ошибка');
    }
  }

  async openDocument(document: PersonDocument) {
    const parameters = {
      hash: document.documentHash,
      personId: this.personId.toString()
    };

    const result = await this.myService.AccessToken({ parameters }).toPromise();
    const component = this.previewService.open(`/api/v2/persons/documents/${result.token}`);

    component.onDownload.subscribe(async () => {
      const response = await this.myService.AccessToken({ parameters }).toPromise();
      window.open(`/api/v2/persons/documents/${response.token}?download=true`, "_blank");
    });
  }

  async printDocument(item: PersonDocument) {
    const parameters = { personId: this.personId.toString(), hash: item.documentHash };

    const response = await this.myService.AccessToken({ parameters }).toPromise();

    const iframe: HTMLIFrameElement = document.createElement("iframe");

    iframe.src = `/api/v2/persons/documents/${response.token}`
    iframe.width = "0";
    iframe.height = "0";
    iframe.style.display = "none";

    document.body.append(iframe);
    iframe.contentWindow.print();
  }

  async downloadDocument(item: Document) {
    const parameters = { personId: this.personId.toString(), hash: item.documentHash };

    const response = await this.myService.AccessToken({ parameters }).toPromise();

    const iframe: HTMLIFrameElement = document.createElement("iframe");

    iframe.src = `/api/v2/persons/documents/${response.token}?download=true`
    iframe.width = "0";
    iframe.height = "0";
    iframe.style.display = "none";

    document.body.append(iframe);
  }

  discountChanged(value: DiscountValue): void {
    this.discountValue = { ...value };
    this.updateFinances();
  }

  discountReasonStatusChanged = (value: boolean) => this.discountReasonRequired = value;
  omsDataChanged = (value: OmsData) => this.omsData = { ...value };

  dmsInfoChanged(dmsInfo: DmsInfo): void {
    if (this.dmsInfo.insuranceCompanyId !== dmsInfo.insuranceCompanyId
      || this.dmsInfo.guaranteeNumber !== dmsInfo.guaranteeNumber) {
      const priceSets = this.recalculateRelevantPriceSets(dmsInfo.insuranceCompanyId, !!dmsInfo.guaranteeNumber);
      const selected: PriceSet = this.selectMostSpecificPriceSet(priceSets, dmsInfo.insuranceCompanyId);

      this.priceSetControl.setValue(selected.id);
      this._priceSetsSource$.next(priceSets);
    }

    this.dmsInfo = { ...dmsInfo };
  }

  private selectMostSpecificPriceSet(priceSets: PriceSet[], insuranceCompanyId: number): PriceSet {
    return priceSets.length === 0 ? undefined :

      priceSets.find(x => x.default && x.companyId === this.companyId && x.insuranceCompanyId === insuranceCompanyId)
      || priceSets.find(x => x.companyId === this.companyId && x.insuranceCompanyId === insuranceCompanyId)
      || priceSets.find(x => x.default && x.insuranceCompanyId === insuranceCompanyId)
      || priceSets.find(x => x.insuranceCompanyId === insuranceCompanyId)
      || priceSets.find(x => x.default && x.companyId === this.companyId)
      || priceSets.find(x => x.companyId === this.companyId)
      || priceSets.find(x => x.default)
      || priceSets[0];
  }

  private async openDuplicateModal(duplicate: DuplicateSearchResponse): Promise<boolean> {
    const options: NgbModalOptions = { backdrop: 'static', centered: true, windowClass: 'duplicate-patient-modal' };
    const modalRef: NgbModalRef = this.modal.open(VisitDuplicateModalComponent, options);
    const componentRef: VisitDuplicateModalComponent = modalRef.componentInstance;

    componentRef.name = duplicate.lastName === duplicate.maidenName ?
      `${duplicate.lastName} ${duplicate.firstName} ${duplicate.middleName}`
      : `${duplicate.lastName} (${duplicate.maidenName}) ${duplicate.firstName} ${duplicate.middleName}`;

    componentRef.birthYear = duplicate.dob;
    componentRef.lastVisit = duplicate.lastVisit;

    try {
      return await modalRef.result;
    } catch (error) {
      return false;
    }
  }

  optionChanged = (change: OptionChange) => change.option.value = change.value;

  pastePhone(event: ClipboardEvent): void {
    const pastedText = event.clipboardData.getData('text');

    if (pastedText && pastedText.startsWith('+7')) {
      this.contactsFormGroup.get('phone').setValue(pastedText.replace('+7', '').replace(/\D/g, ''));
    }
    else if (pastedText && pastedText.startsWith('7') && pastedText.length === 11) {
      this.contactsFormGroup.get('phone').setValue(pastedText.replace(/^7/, '').replace(/\D/g, ''));
    }
    else if (pastedText && pastedText.startsWith('8') && pastedText.length === 11) {
      this.contactsFormGroup.get('phone').setValue(pastedText.replace(/^8/, '').replace(/\D/g, ''));
    }
  }

  issuerLoader = (term: string) => this.suggestionsService.Issuers({ code: term });
  selectIssuer = (issuer: string) => this.patientDataForm.get('documentIssuedBy').setValue(issuer);
  selectRepIssuer = (issuer: string) => this.representativeFormGroup.get('issuer').setValue(issuer);

  private removePatientDocumentValidators(): void {
    this.patientDataForm.controls["documentNo"].clearValidators();
    this.patientDataForm.controls["documentNo"].updateValueAndValidity();
    this.patientDataForm.controls["documentDate"].clearValidators();
    this.patientDataForm.controls["documentDate"].updateValueAndValidity();
    this.patientDataForm.controls["documentIssuedBy"].clearValidators();
    this.patientDataForm.controls["documentIssuedBy"].updateValueAndValidity();
  }

  private setPatientDocumentValidators(): void {
    this.patientDataForm.controls["documentNo"].setValidators([Validators.required]);
    this.patientDataForm.controls["documentNo"].updateValueAndValidity();
    this.patientDataForm.controls["documentDate"].setValidators([isAfter(this.patientDataForm.controls['dob']), Validators.required]);
    this.patientDataForm.controls["documentDate"].updateValueAndValidity();
    this.patientDataForm.controls["documentIssuedBy"].setValidators([Validators.required]);
    this.patientDataForm.controls["documentIssuedBy"].updateValueAndValidity();
  }

  loadBalance(personId: number): void {
    if (!personId) {
      this.balance = 0;
      return;
    }

    this.personalBalanceService.GetPersonalBalanceAsync(personId)
      .subscribe(
        (response: PersonalBalance) => this.balance = response.total,
        (response: HttpErrorResponse) => this.toastrService.warning("Не удалось загрузить баланс пациента", "Ошибка")
      );

  }

  addMark() {
    const modalRef = this.personMarkModalServuce.open();
    const componentRef: PersonMarkModalComponent = modalRef.componentInstance;

    componentRef.colorMarks = this.colorMarks;

    componentRef.onConfirm.subscribe((payload: PersonMarkModalPayload) => {
      componentRef.processing = true;

      if (this.marks.some(x => x.colorMarkId === payload.colorMarkId)) {
        const mark = this.marks.find(x => x.colorMarkId === payload.colorMarkId);

        this.toastrService.warning(`Метка "${mark.colorMarkName}" уже добавлена`, 'Ошибка');
        componentRef.processing = false;
        return;
      }

      const colorMark = this.colorMarks.find(x => x.id === payload.colorMarkId);

      if (!colorMark) {
        this.toastrService.warning(`Метка не найдена`, 'Ошибка');
        componentRef.processing = false;
        return;
      }

      this.marks.push({
        authorId: this.userStorage.profile.id,
        colorMarkId: colorMark.id,
        colorMarkName: colorMark.name,
        colorMarkValue: colorMark.color,
        description: payload.description
      });

      componentRef.processing = false;
      modalRef.close();
    });

    componentRef.onCancel.subscribe(() => {
      componentRef.processing = false;
      modalRef.close();
    });
  }

  canRemoveMark = (mark: PersonMark) => !mark.id || this.canManageMarks || (this.canAddMark && mark.authorId === this.userStorage.profile.id);

  async removeMark(mark: PersonMark) {
    const confirm = await this.confirmationService.open({ message: `Метка "${mark.colorMarkName}" будет удалена. Продолжить?`, confirmBtnText: 'Продолжить' });

    if (!confirm) return;


    if (!mark.id) {
      this.marks = this.marks.filter(x => x.colorMarkId !== mark.colorMarkId);
      return;
    }

    if (this.canManageMarks || (this.canAddMark && mark.authorId === this.userStorage.profile.id)) {
      this.marks = this.marks.filter(x => x.id !== mark.id);
      return;
    }
  }

}

class SummaryLocus {
  name = '';
  containers: SummaryContainer[] = [];
}

class SummaryContainer {
  name = '';
  total = 0
}

interface Contacts {
  phone: string;
  email: string;
}

interface RepresentativeFormValue {
  firstname: string;
  middlename: string;
  lastname: string;

  payer: boolean;

  passportNumber: string;
  issuer: string;
  issued: moment.Moment;
  issuerCode: string;
}

interface ContactsFormValue {
  phone: string;
  email: string;
}

function minDob(min: moment.Moment): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || !moment.isMoment(control.value)) return null;
    return min.isAfter(control.value, 'day') ? { minDob: { value: min } } : null;
  };
}

function maxDob(max: moment.Moment): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || !moment.isMoment(control.value)) return null;
    return max.isBefore(control.value, 'day') ? { maxDob: { value: max } } : null;
  };
}

function isAfter(other: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control || !control.value || !moment.isMoment(control.value)) return null;
    if (!other || !other.value || !moment.isMoment(other.value)) return null;

    return control.value.isAfter(other.value, 'day') ? null : { isAfter: true };
  }
}

