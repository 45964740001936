import { Injectable } from '@angular/core';
import { Patient, PriceSet, PriceSetsResponse, ColorMark, ColorMarksResponse, ServiceCategory, ServiceCategoriesResponse, Service, ServicesResponse, Registrator, Company, PersonInfo } from '../../../generated/models';
import { PatientsService, CustomerChannelsService, PriceSetsService, ColorMarksService, ServiceCategoriesService, ServicesService, VisitsService, EmployeesService, PartnersService, DiscountsService, CompaniesService, PeopleService } from '../../../generated/services';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, from, of } from 'rxjs';
import { CustomerChannel } from '../../../generated/models/customer-channel';
import { map } from 'rxjs/operators';
import { VisitEditorModel } from '../../../generated/models/visit-editor-model';

@Injectable({ providedIn: "root" })
export class VisitResolver implements Resolve<VisitPayload>  {

  public constructor(
    private patientsService: PatientsService,
    private customerChannelsService: CustomerChannelsService,
    private colorMarksService: ColorMarksService,
    private serviceCategoriesService: ServiceCategoriesService,
    private servicesService: ServicesService,
    private visitService: VisitsService,
    private employeesService: EmployeesService,
    private companiesService: CompaniesService,
    private priceSetsService: PriceSetsService,
    private peopleService: PeopleService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<VisitPayload> {
    const id: number = parseInt(route.paramMap.get("id"), 10);
    const primary: boolean = !route.queryParamMap.has("primary") || route.queryParamMap.get("primary") !== "false";
    const parentId: number = parseInt(route.queryParamMap.get("parentId"), 10);

    const patientId: number = parseInt(route.queryParamMap.get("patientId"), 10);
    const personId: number = parseInt(route.queryParamMap.get("personId"), 10);

    let patientLoader: Observable<Patient> = from([{}]);

    let visitLoader: Observable<VisitEditorModel> = this.visitService.New();

    if (parentId) {
      visitLoader = this.visitService.New(parentId);
    }

    let servicesLoader: Observable<ServicesResponse> = this.companiesService.CurrentAvailableServicesAsync("");

    if (id > 0) {
      visitLoader = this.visitService.Visit(id);
      patientLoader = this.patientsService.PatientForVisit(id);
      servicesLoader = this.visitService.AvailableServices(id);

    } else if (patientId > 0) {
      patientLoader = this.patientsService.Patient(patientId);
    } else if (personId > 0) {
      patientLoader = this.peopleService.PersonAsync(personId)
        .pipe(
          map((response: PersonInfo): Patient => {
            let gender: 0 | 1 | 2 | 3 = 0;
            if (response.gender === 'f') {
              gender = 1;
            }

            if (response.gender === 'm') {
              gender = 2
            }

            return ({
              ...response,
              id: undefined,
              personId: response.id,
              gender
            });
          })
        );

    }

    const colorMarksLoader: Observable<ColorMark[]> = this.colorMarksService.ColorMarks({
      Page: 1,
      Size: 0
    })
      .pipe(
        map((response: ColorMarksResponse): ColorMark[] => response.items)
      );

    const serviceCategoriesLoader: Observable<ServiceCategory[]> = this.serviceCategoriesService.Categories({ Page: 1, Size: 0 })
      .pipe(
        map((response: ServiceCategoriesResponse): ServiceCategory[] => response.categories)
      );

    const registratorsLoader: Observable<Registrator[]> = this.employeesService.Registrators();
    const priceSetsLoader: Observable<PriceSet[]> = this.priceSetsService.PriceSets({ Page: 1, Size: 0 }).pipe(map(x => x.priceSets));

    const dmsInsuranceCompaniesLoader: Observable<Company[]> = this.companiesService.Companies({ Type: 5 });
    const omsInsuranceCompaniesLoader: Observable<Company[]> = this.companiesService.Companies({ Type: 3 })

    return forkJoin({
      patient: patientLoader,
      channels: this.customerChannelsService.Channels(),
      colorMarks: colorMarksLoader,
      serviceCategories: serviceCategoriesLoader,
      services: servicesLoader.pipe(map(x => x.services)),
      visit: visitLoader,
      registrators: registratorsLoader,
      priceSets: priceSetsLoader,

      dmsInsuranceCompanies: dmsInsuranceCompaniesLoader,
      omsInsuranceCompanies: omsInsuranceCompaniesLoader,

      primary: of(primary),
      parentId: of(parentId)
    });
  }
}

export class VisitPayload {
  patient: Patient;
  channels: CustomerChannel[];
  colorMarks: ColorMark[];
  serviceCategories: ServiceCategory[];
  services: Service[];
  visit: VisitEditorModel;
  registrators: Registrator[];
  priceSets: PriceSet[];

  dmsInsuranceCompanies: Company[];
  omsInsuranceCompanies: Company[];

  primary: boolean;
  parentId: number;
}
