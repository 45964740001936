import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisitsComponent } from './components/visits/visits.component';
import { VisitComponent } from './components/visit/visit.component';
import { Routes, RouterModule } from '@angular/router';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { LayoutModule } from "@skytecs/layout";
import { ControlsModule } from "@skytecs/controls";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbDropdownModule, NgbAccordionModule, NgbModalModule, NgbTypeaheadModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSelectModule } from 'ngx-select-ex';
import { SharedModule } from '../shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';
import { VisitResolver } from './resolvers/visit-resolver';
import { SelectClientModalComponent } from './components/select-client-modal/select-client-modal.component';
import { VisitsContainerSelectModalComponent } from './components/visits-container-select-modal/visits-container-select-modal.component';
import { VisitsPerformerSelectModalComponent } from './components/visits-performer-select-modal/visits-performer-select-modal.component';
import { ServicesListComponent } from './components/services-list/services-list.component';
import { SelectedServicesListComponent } from './components/selected-services-list/selected-services-list.component';
import { SelectedServicesItemComponent } from './components/selected-services-item/selected-services-item.component';
import { AnamnesusItemComponent } from './components/anamnesus-item/anamnesus-item.component';
import { AnamnesisListComponent } from './components/anamnesis-list/anamnesis-list.component';
import { VisitPaymentsComponent } from './components/visit-payments/visit-payments.component';
import { PermissionNames } from '../../models/permission-names';
import { DiscountFieldComponent } from './components/discount-field/discount-field.component';
import { VisitTabsComponent } from './components/visit-tabs/visit-tabs.component';
import { VisitTabComponent } from './components/visit-tab/visit-tab.component';
import { DiscountBadgeComponent } from './components/discount-badge/discount-badge.component';
import { SpendingsBadgeComponent } from './components/spendings-badge/spendings-badge.component';
import { OmsComponent } from './components/oms/oms.component';
import { PatientSearchComponent } from './components/patient-search/patient-search.component';
import { SelectClientItemComponent } from './components/select-client-item/select-client-item.component';
import { VisitDuplicateModalComponent } from './components/visit-duplicate-modal/visit-duplicate-modal.component';
import { CorrectionReceiptModule } from '../correction-receipt/correction-receipt.module';
import { OptionsComponent } from './components/options/options.component';
import { OptionComponent } from './components/option/option.component';
import { FiscalPrinterModalComponent } from './components/fiscal-printer-modal/fiscal-printer-modal.component';
import { ExternalDeviceComponent } from './components/external-device/external-device.component';
import { DmsInfoComponent } from './components/dms-info/dms-info.component';
import { IssuerCodeComponent } from './components/issuer-code/issuer-code.component';
import { RefundModalComponent } from './components/refund-modal/refund-modal.component';
import { VisitServicePrescriptionsComponent } from './components/visit-service-prescriptions/visit-service-prescriptions.component';
import { BalanceManagementModalComponent } from '../balance-management/components/balance-management-modal/balance-management-modal.component';
import { BalanceManagementModule } from '../balance-management/balance-management.module';
import { TaxDeductionsModalComponent } from '../tax-deductions/components/tax-deductions-modal/tax-deductions-modal.component';
import { TaxDeductionsModule } from '../tax-deductions/tax-deductions.module';
import { PrescriptedServiceItemComponent } from './components/prescripted-service-item/prescripted-service-item.component';
import { PersonMarksModule } from '../person-marks/person-marks.module';
import { ServiceDetailsModalComponent } from './components/service-details-modal/service-details-modal.component';
import { ServiceDetailsModalItemComponent } from './components/service-details-modal-item/service-details-modal-item.component';

const routes: Routes = [
  {
    path: "new",
    component: VisitComponent,
    data: {
      title: "Новое посещение",
      requirements: [PermissionNames.CreateVisit]
    },
    resolve: {
      payload: VisitResolver
    }
  },
  {
    path: ":id",
    component: VisitComponent,
    data: {
      title: "Посещение",
      requirements: [PermissionNames.CreateVisit]
    },
    resolve: {
      payload: VisitResolver
    }
  },
  {
    path: "",
    component: VisitsComponent,
    data: {
      title: "Посещения",
      requirements: [PermissionNames.ViewVisits]
    }
  }
];

@NgModule({
  declarations: [
    VisitsComponent,
    VisitComponent,
    SelectClientModalComponent,
    VisitsContainerSelectModalComponent,
    VisitsPerformerSelectModalComponent,
    ServicesListComponent,
    SelectedServicesListComponent,
    SelectedServicesItemComponent,
    AnamnesusItemComponent,
    AnamnesisListComponent,
    VisitPaymentsComponent,
    DiscountFieldComponent,
    VisitTabsComponent,
    VisitTabComponent,
    DiscountBadgeComponent,
    SpendingsBadgeComponent,
    OmsComponent,
    PatientSearchComponent,
    SelectClientItemComponent,
    VisitDuplicateModalComponent,
    OptionsComponent,
    OptionComponent,
    FiscalPrinterModalComponent,
    ExternalDeviceComponent,
    DmsInfoComponent,
    IssuerCodeComponent,
    RefundModalComponent,
    VisitServicePrescriptionsComponent,
    PrescriptedServiceItemComponent,
    ServiceDetailsModalComponent,
    ServiceDetailsModalItemComponent
  ],
  entryComponents: [
    SelectClientModalComponent,
    VisitsContainerSelectModalComponent,
    VisitsPerformerSelectModalComponent,
    VisitDuplicateModalComponent,
    FiscalPrinterModalComponent,
    RefundModalComponent,
    BalanceManagementModalComponent,
    TaxDeductionsModalComponent,
    ServiceDetailsModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    FormsModule,
    InfiniteScrollModule,
    NgbDropdownModule,
    NgxSelectModule,
    NgbAccordionModule,
    NgxMaskModule.forRoot({}),

    LayoutModule,
    ControlsModule,

    SharedModule,
    PersonMarksModule,
    NgbModalModule,
    CorrectionReceiptModule,
    NgbTypeaheadModule,
    NgbTimepickerModule,

    BalanceManagementModule,
    TaxDeductionsModule
  ],
  exports: [
    RouterModule
  ]
})
export class VisitsModule { }
